import React, { useState,useEffect } from 'react';
import styled from 'styled-components';
import { Link,useHistory } from 'react-router-dom';
import * as FaIcons from 'react-icons/fa';
import { SidebarData,SidebarDatadropdown, Datadropdown,DatadropdownRadiology,DatadropdownPathology, DatadropdownPathologyAdmin } from './SidebarData';
import SubMenu from './SubMenu';
import { IconContext } from 'react-icons/lib';
import axios from '../helper/axios';
import { useUser } from "./../Provider";
// Here we are using react styled component for styling
//we are not using any css file for styling
// Here we have styled the navigation bar bsckground
// const Nav = styled.div`
//   background: #e6e5e5;
//   height: 48px;
//   display: flex;
//   justify-content: flex-start;
//   align-items: center;
//   width: 100%;
// `;
const Nav = styled.div`
  background: #e6e5e5;
  height: 48px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  @media (max-width: 480px) {
    height: 74px;
    justify-content: space-between;
    padding: 0 16px;
    width: 100%;
  }
`;

// Here we have styled the navigation bar icons size and positions
const NavIcon = styled(Link)`
  margin-left: 2rem;
  font-size: 1rem;
  height: 60px;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  @media (max-width: 480px) {
    margin-left: 1rem;
    font-size: 1.2rem;
    margin-top: 2.2rem;
  }
  
`;
const Navbar = styled.div`
border: 1px solid #d4dce4;
box-sizing: border-box;
height: 50px;
margin-left:40px;
width:88.5vw;
background: #ececec;

@media (max-width: 480px) {
  margin-left: 10px;
  width: 100vw;
  background: #e6e5e5;
  border: 1px solid #e6e5e5;
}
`;
// Here we have styled the  icons size and position present in rightside of navigation bar
const Navicon = styled.div`
width:25vw;
margin-left:55vw;
margin-right:0px;
cursor: pointer;
font-size: 1.5rem;
display: flex;
justify-content: space-between;
align-items: center;
margin-top: 10px;
position:relative;

@media (max-width: 480px) {
  width:100px;
  margin-left:50px;
  margin-right:0px;
  justify-content: space-around;
}
`;

// Here we have styled the navigation bar heading
const NavHeading =styled.h2`
color: #000;
  margin-left: 12px;
  width: 13vw;

  @media (max-width: 1500px) {
    width: 12.5vw;
  }

  @media (max-width:480px) {
    width: auto;
  }
`;
// Here we have styled the background and size of sidebar navigation
// left keyword is used open and close the side bar when we click on cross icon
const SidebarNav = styled.nav`
  background: #2d373c;
  width:16vw;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 10;
  transition: 350ms;
  z-index: 1000;
  overflow-y: scroll;
  height: 92vh;
  padding: 0 0 20px 0;
  @media screen and (max-width: 480px) {
    width: 70%;
    left: ${({ sidebar }) => (sidebar ? '0' : '-100%')};
  }
`;
// Here we have styled the  sidebarwrap
const SidebarWrap = styled.div`
  width: 100%;
  
`;

const Sidebar = () => {
  const history = useHistory();
 // const { userHospitalcode, userrole1, isAuthorized, token } = useUser();
  const token = localStorage.getItem("jwtToken");
const [sidebar, setSidebar] = useState(false);
const showSidebar = () => setSidebar(!sidebar);
const [windowWidth, setWindowWidth] = useState(window.innerWidth);
const handleResize = () => {
  setWindowWidth(window.innerWidth);
};
const isMobile = windowWidth <= 480;
useEffect(() => {
  window.addEventListener('resize', handleResize);
  return () => {
    window.removeEventListener('resize', handleResize);
  };
}, []);
const [userdesignation, setDesignation] = useState();
const [gettokenId, setTokenId] = useState();
 const [userHospitalcode, setUserHospitalcode] = useState();
 const [userrole1, setUserrole1] = useState();
const [isAuthorized, setIsAuthorized] = useState(false);
const [ isUserAdmin, setIsUserAdmin] = useState(false);
const [ isUserPathology, setIsUserPathology] = useState(false);
const [ isUserPathologyrole, setIsUserPathologyrole] = useState(false);
const [ isUserRadiology, setIsUserRadiology] = useState(false);
 // Function to get login user's details
 const loadAllDetails = async () => {
  try {
    const response = await axios.post('/loginuser', null, {
      headers: { Authorization: `Bearer ${token}` },
    });

    // Debugging: Log the entire response
    console.log("Response:", response);

    const UserRoles = response.data.person[0][0].role1;
    const hospitalcode = response.data.person[0][0].hospitalcode;
      const employeeid = response.data.person[0][0].empid;
      const designation = response.data.person[0][0].designation;
      // Debugging: Log the values of userHospitalcode and userrole1
      console.log("userHospitalcode:", hospitalcode);
      console.log("designation:", designation);
      setTokenId(employeeid);
      setUserHospitalcode(hospitalcode);
      setUserrole1(UserRoles);
      setDesignation(designation);
      // Debugging: Log the condition result
      const authorizationResult = hospitalcode === 1 && UserRoles === 'Superadmin';
      const izationResult = UserRoles === 'Admin' && hospitalcode !== 4;
      const checkAuthorizationrole =(UserRoles === 'Employee' || UserRoles === 'Admin') && hospitalcode === 4;
      const checkAuthorization = (UserRoles === 'Employee' || UserRoles === 'Admin') && designation === 'Pathologist';
      const checkRadiology = UserRoles === 'Employee' && designation === 'Radiologist'
      console.log("Authorization Result:", authorizationResult);
      setIsAuthorized(authorizationResult);
      setIsUserAdmin(izationResult);
      setIsUserPathology(checkAuthorization);
      setIsUserPathologyrole(checkAuthorizationrole);
      setIsUserRadiology(checkRadiology)
      console.log("isAuthorized after setIsAuthorized:", isAuthorized);
    } catch (error) {
      console.error("Error:", error);

      if (error.response.status === 403) {
        alert('Token Expired. Please login again.');
        localStorage.removeItem('jwtToken');
        history.push('/');
      } else if (error.response.status === 401) {
        alert('Invalid token. Please login again.');
        localStorage.removeItem('jwtToken');
        history.push('/');
      } else {
        alert('Error: ' + error.response.data.error);
      }
    }
  };
  //when page load or refresh happen this function will got called
  useEffect(() => {
    const loadData = async () => {
      await loadAllDetails();

    };

    loadData();
  }, [userrole1, userHospitalcode]);
  return (
    <>
      {/* will provide the color for icons where ever it is used */}
      <IconContext.Provider value={{ color: '#fff' }}>

        <div>
          {isMobile && (
            <NavIcon to='#'>
              <FaIcons.FaBars style={{ color: '#000000' }} onClick={showSidebar} />
            </NavIcon>
          )}
        </div>
        <SidebarNav sidebar={sidebar}>
          <SidebarWrap>
            {/* {isAuthorized ? (
  // If user is Superadmin, display SidebarData
  SidebarData.map((item, index) => {
    return <SubMenu item={item} key={index} />;
  })
) : (
  // If user is Admin
  isUserAdmin ? (
    SidebarDatadropdown.map((item, index) => {
      return <SubMenu item={item} key={index} />;
    })
  ) : (
    // If user is Employee
    Datadropdown.map((item, index) => {
      return <SubMenu item={item} key={index} />;
    })
  )
)} */}

            {isAuthorized ? (
              // If user is Superadmin, display SidebarData
              SidebarData.map((item, index) => {
                return <SubMenu item={item} key={index} />;
              })
            ) : isUserAdmin ? (
              // If user is Admin, display SidebarDatadropdown
              SidebarDatadropdown.map((item, index) => {
                return <SubMenu item={item} key={index} />;
              })
            ) : isUserRadiology ? (
              // If user is Radiology user, display DatadropdownRadiology
              DatadropdownRadiology.map((item, index) => {
                return <SubMenu item={item} key={index} />;
              })
            ) : isUserPathology ? (
              // If user is Pathology user, display DatadropdownPathology 
              DatadropdownPathology.map((item, index) => {
                return <SubMenu item={item} key={index} />;
              })
            ) :  isUserPathologyrole ? (
              // If user is Pathology user, display DatadropdownPathology 
              DatadropdownPathologyAdmin.map((item, index) => {
                return <SubMenu item={item} key={index} />;
              })
            ) : (
              // If user is not Admin or Pathology user, display regular Datadropdown
              Datadropdown.map((item, index) => {
                return <SubMenu item={item} key={index} />;
              })
            )}

          </SidebarWrap>
        </SidebarNav>
      </IconContext.Provider>
    </>
  );
};

export default Sidebar;
