import axios from "axios"

const API = axios.create({
   // baseURL: "http://localhost:3008/api",
    baseURL: "https://aarogyasaarthi.in/api",
  //baseURL: "https://aarogyasaarthi.com/api",
});

export default API;


