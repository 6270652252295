import React, { useState, useEffect } from 'react';
import axios from "../../../helper/axios";
import { useFormik } from "formik";
import { useUser } from "../../../Provider";
import { useParams } from 'react-router-dom/cjs/react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { pathologyreportvalidation } from "../../../helper/schemas";
import { pathologyreportvalidation2 } from "../../../helper/schemas";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Import the styles
import Billingsearch from '../../../Component/Billingsearch';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Table, Button, Modal, Form } from 'react-bootstrap';
import File from "../../../Component/PrintReport/File";
import Searchbar from '../../../Component/Searchbar';
import BillingSearchPerPatient from '../../../Component/BillingSearchPerPatient';

// Initial values for the form fields
const initialValues = {
 hospitalcode:"",
 patientid:"",
 name: '',
 age: '',
 sex: '',
 medicalHistory: '',
};

const AddPathologyReportForm = () => {
  const history = useHistory();
  // Use the 'useUser' hook to get user-related information
  const { user, userHospitalcode, userrole1, isAuthRole, isAuthorized, gettokenId, token } = useUser();
  console.log(user, userHospitalcode, userrole1, isAuthRole, isAuthorized, gettokenId);
  const [category1, setCategory] = useState([]);
  const [isPopup, setIsPopup] = useState(false);
  const [data, setData] = useState([]);
  const [billnumber, setBid] = useState();
  const [error, setError] = useState(false);
  const[searchValueName,setSearchValueName]=useState("")
  const [searchValue, setSearchValue] = useState("");
  // Get today's date to initialize the form field
  let Showdate = new Date();
  let ShowTodaydate =
    Showdate.getFullYear() +
    "-" +
    (Showdate.getMonth() + 1) +
    "-" +
    Showdate.getDate();
  const [currentDate, setCurrentDate] = useState(ShowTodaydate);
  const getValidationSchema = () => {
    if (!isAuthorized ) {
      return pathologyreportvalidation2;
    }
    else if (isAuthorized) {
      return pathologyreportvalidation;
    }
  };
  // Formik hook for form state management and validation
  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleReset,
    handleSubmit,
    resetForm,
    setValues,
  } = useFormik({
    initialValues,
    validationSchema:getValidationSchema,
    onSubmit: async (values, action) => {
      if (!searchValueName) {
        toast.error("Please select a bill number.");
        return;
      }
      // Check if any of the rate field is empty
    const isAnyRateEmpty = test.some((test) => test.testcategory === "");
    if (isAnyRateEmpty) {
      toast.error("Test Category field cannot be empty .");
      return;
    }
       // Check if any of the result field is empty
    const isAnyresult = test.some((test) => test.result === "");
    if (isAnyresult) {
      toast.error("Result field cannot be empty .");
      return;
    }
      try {
        setIsPopup(false)
        const data = new FormData();
        // Append form data to the FormData object
        data.append("document", JSON.stringify(searchValueName));
        data.append("documentvalue", JSON.stringify(values));
        data.append("currentDate", JSON.stringify(currentDate));
        data.append("gettokenId", JSON.stringify(gettokenId));
        data.append("test", JSON.stringify(test));
        data.append("userHospitalcode", JSON.stringify(userHospitalcode)); 
        console.log(JSON.stringify(searchValueName))
        console.log(JSON.stringify(test))
        // Make a POST request to save Prescription data
        const response = await axios.post(`/addpathologyreport`, data, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        });

        // Display success message if the request is successful
        if (response.status === 200) {
           const billnumber = searchValueName.billnumber
           setBid(billnumber);
           if (isPopup) {
             setIsPopup(true)
           }
           setError(false)
          toast.success("Data Saved");
        }
      } catch (err) {
        // Handle errors
      }
      // Reset the form after submission
      action.resetForm();
    },
  });

  // State variables for managing test-related data
  const [test, setTest] = useState([{ testcategory: '', testparameter: '', result: '', unit: '', referencerange: '', method: '' }]);
  // Function to handle changes in test-related fields
  const handleChanges = (e, index, field) => {
    const { value } = e.target;
    setTest((prevTests) => {
      return prevTests.map((test, i) => {
        if (i === index) {
          return { ...test, [field]: value };
        }
        return test;
      });
    });
  };
  
 // Function to load hospital codes
 const loadHospitalcode = async () => {
  if (userHospitalcode !== undefined && userrole1 !== undefined) {
    const res = await axios.get("/gethospitalcode", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        hospitalcode: userHospitalcode,
        userrole: userrole1,
      },
    });
    setData(res.data);
  };
}
//Get the madicine name according to search billingnumber
const fetchMedicineCateoryNames = async (selectedHospitalCode) => {
  try {
    const response = await axios.get(`/getbillingdetailpathology/${searchValueName.billnumber}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        hospitalcode: searchValueName.hospitalcode,
      },
    });

    if (response.status === 200) {
      // Assuming response.data is an object containing test parameters, result, unit, reference range, and method
      const testData = response.data;
      
      // Update the test state with the fetched data
      setTest(testData.map(data => ({
        chargecode: data.chargecode,
        testcategory: data.chargename,
        testparameter: data.parametername,
        result: "",
        unit: data.unitname,
        referencerange: data.referencerange,
        method: data.method
      })));
    } else {
      console.error(`Failed to fetch test details with status: ${response.status}`);
    }
  } catch (error) {
    console.error('Error fetching test details:', error.message);
  }
};

  useEffect(() => {
    if (searchValueName) {
      setValues({
        ...values,
        hospitalcode:searchValueName.hospitalcode,
        patientid:searchValueName.patientid,
        name: searchValueName.patientname,
        age: searchValueName.age,
        sex: searchValueName.gender,
        // Update other fields as needed
      });
     
     const loadData = async () => {
      await loadHospitalcode();
      await fetchMedicineCateoryNames(searchValueName.billnumber);
     };
     loadData();
    }
  }, [ searchValueName, token,searchValueName.billnumber]);



// Handel the billing search
  const handleSearch = (searchTerm) => {
    if (searchTerm) {
    console.log(searchTerm);
    setSearchValueName(searchTerm); // Update the searchValue state with the search term
  }else{
    setSearchValue("");
    }
  }


const handleSearchChange = async (searchTerm) => {
    if (searchTerm) {
      console.log(searchTerm);
      setSearchValue(searchTerm); // Update the searchValue state with the search term
      // Assuming searchTerm contains patient information including hospital code
      const selectedHospitalCode = searchTerm.hospitalcode;
     console.log("-----------------",selectedHospitalCode)
      await fetchMedicineCateoryNames(searchValueName.billnumber);
    } else {
      // Handle the case when searchTerm is null, you can reset the searchValue state
      setSearchValue("");
    }
  };


  // Handel the patient search
  // const handleSearchChange = (searchTerm) => {
  //   console.log(searchTerm)
  //   setSearchValue(searchTerm); // Update the searchValue state with the search term
  // };


  const handleClosed = () => {
    setIsPopup(false)
    //window.location.reload();
  }
  // print function
  const printForm = (values) => {
      setBid(searchValueName.billnumber)
      setIsPopup(true)
  }
  return (
    <div>
      <form onSubmit={handleSubmit}>
      <div className="patient-new primary-info">
        <div className="row  ml-4">
          <div className="col-md-12 ">
          <div className="row">
                <div className="col-md-2">
                  <label className="col-form-label ">Search Patient</label>
                </div>
          
                <div className="col-md-4">
                  <Searchbar onSearchChange={handleSearchChange} />
                </div>
            </div>
            <div className="row">
                <div className="col-md-2">
                  <label className="col-form-label ">Billing No</label>
                </div>
                <div className="col-md-4">
  {searchValue.patientid ? (
    <BillingSearchPerPatient onSearchChange={handleSearch} searchValue={searchValue} />
  ) : (
    <Billingsearch onSearchChange={handleSearch} />
  )}
</div>
            
               {isAuthorized ? ( <div className="col-md-2">
                  <label
                    htmlFor="hospitalcode"
                    className="col-form-label"
                  >
                    Hospital Code
                  </label>
                </div>) : (
                null
              )}
                <div className="col-md-4">
               
                {isAuthorized ? (
                <input
                type="text"
                autoComplete="off"
                name="hospitalcode"
                id="hospitalcode"
                placeholder="Hospital Code"
                value={values.hospitalcode}
                onChange={handleChange}
                onBlur={handleBlur}
                disabled
                className="form-control"
             />
               
            ) : (
              <input
                type="hidden"
                name="hospitalcode"
                id="hospitalcode"
                value={values.hospitalcode}
                disabled={true} // Set the input as disabled
                className="form-control "
              />
              )}
                 {errors.hospitalcode && touched.hospitalcode ? (
                  <p className="form-erroremployee">{errors.hospitalcode}</p>
                ) : null}
                </div>
            </div>
            <div className="row">
  <div className="col-md-12">

        <h5>Patient Information</h5>
        <div className="row">
          <div className="col-md-6">
            <div className="row">
              <div className="col-md-4">
                <label htmlFor="name" className="col-form-label leftone">Name</label>
              </div>
              <div className="col-md-8">
                <input
                  type="text"
                  autoComplete="off"
                  name="name"
                  id="name"
                  placeholder="Name"
                  value={values.name}
                  onChange={handleChange}
                  disabled
                  className="form-control"
                />
                {errors.name && touched.name ? (
                  <p className="form-erroremployee">{errors.name}</p>
                ) : null}
              </div>
            </div>
            <div className="row">
              <div className="col-md-4">
                <label htmlFor="age" className="col-form-label leftone">Age</label>
              </div>
              <div className="col-md-8">
                <input
                  type="number"
                  autoComplete="off"
                  name="age"
                  id="age"
                  placeholder="Age"
                  value={values.age}
                  onChange={handleChange}
                  disabled
                  className="form-control"
                />
                {errors.age && touched.age ? (
                  <p className="form-erroremployee">{errors.age}</p>
                ) : null}
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="row">
              <div className="col-md-4">
                <label htmlFor="sex" className="col-form-label leftone">Sex</label>
              </div>
              <div className="col-md-8">
                <input
                  type="text"
                  autoComplete="off"
                  name="sex"
                  id="sex"
                  placeholder="Sex"
                  value={values.sex}
                  onChange={handleChange}
                  disabled
                  className="form-control"
                />
                {errors.sex && touched.sex ? (
                  <p className="form-erroremployee">{errors.sex}</p>
                ) : null}
              </div>
            </div>
            <div className="row">
              <div className="col-md-4">
                <label htmlFor="medicalHistory" className="col-form-label">Medical History</label>
              </div>
              <div className="col-md-8">
                <input
                  type="text"
                  autoComplete="off"
                  name="medicalHistory"
                  id="medicalHistory"
                  placeholder="Medical History"
                  value={values.medicalHistory}
                  onChange={handleChange}
                  className="form-control"
                />
                {errors.medicalHistory && touched.medicalHistory ? (
                  <p className="form-erroremployee">{errors.medicalHistory}</p>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
</div>
          </div>
           
            </div>
            </div>
      <div>
            <table>
              <thead>
                <tr style={{ paddingRight: '10px' }}>
                  <th>Test Category</th>
                  <th>Pathology Parameter</th>
                  <th>Result</th>
                  <th>Unit</th>
                  <th>Reference Range</th>
                  <th>Method</th>
                  {/* <th>Delete</th> */}
                </tr>
              </thead>
              <tbody>
                {test.map((test, index) => (
                  <tr key={index}>
                    <td style={{ paddingRight: '10px' }}>
                      <input
                        type="text"
                        autoComplete="off"
                        name="testcategory"
                        id="testcategory"
                        placeholder="Test Category"
                        value={test.testcategory}
                       // onChange={(e) => handleCategoryChangeForRow(e, index,"testcategory")}
                        onBlur={handleBlur}
                        style={{ width: '100%', height: '32px', border: '1px solid #ced4da ', color: '#495057' }}
                      />
                        {/* <option>Select</option>
                        {category1.map((code) => (
                          <option value={code.chargecode} key={code.chargecode}>
                            {code.chargename}
                          </option>
                        ))}
                      </select> */}
                    </td>
                    <td>
                      <input
                        type="text"
                        autoComplete="off"
                        name="testparameter"
                        id="testparameter"
                        placeholder="test Name"
                        value={test.testparameter}
                        disabled
                       // onChange={(e) => handleChanges(e, index, "testparameter")}
                        style={{ width: '100%', height: '32px', border: '1px solid #ced4da ', color: '#495057',background:'#ced4da' }}
                     />
                    </td>
                     
                    <td>
                      <input
                        type="text"
                        autoComplete="off"
                        name="result"
                        id="result"
                        placeholder="result"
                        value={test.result}
                        onChange={(e) => handleChanges(e, index, "result")}
                       

                        style={{ width: '100%', height: '32px', border: '1px solid #ced4da ', color: '#495057' }}
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        autoComplete="off"
                        name="unit"
                        id="unit"
                        placeholder="unit"
                        value={test.unit}
                       // onChange={(e) => handleChanges(e, index, "unit")}
                       disabled
                        style={{ width: '100%', height: '32px', border: '1px solid #ced4da ', color: '#495057',background:'#ced4da' }}
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        autoComplete="off"
                        name="referencerange"
                        id="referencerange"
                        placeholder="referencerange"
                        value={test.referencerange}
                        disabled
                        //  onChange={(e) => handleChanges(e, index, "referencerange")}
                        style={{ width: '100%', height: '32px', border: '1px solid #ced4da ', color: '#495057',background:'#ced4da' }}
                      />
                    </td>
                    <td>
                      <textarea
                        // style={{ width: "210px", height: "50px" }}
                        rows="1"
                        cols="30"
                        type="text"
                        autoComplete="off"
                        name="method"
                        id="method"
                        placeholder="method"
                        value={test.method}
                       // onChange={(e) => handleChanges(e, index, "method")}
                      disabled
                        style={{ width: '100%', height: '32px', border: '1px solid #ced4da ', color: '#495057',background:'#ced4da' }}
                      />
                    </td>
                    {/* <td>
                    <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        style={{ width: 'auto' }}
                        onClick={(e) => handleDeleteRow(index)}
                      >
                        Delete
                      </Button>
                    </td> */}
                  </tr>
                ))}
              </tbody>
            </table>
            {/* <Button variant="contained" color="primary" size="small" style={{ width: 'auto' }}
             onClick={() => setTest([...test, { testcategory: '', testparameter: '', result: '', unit: '', referencerange: '', method: '' }])}>
              Add Test
            </Button> */}
          </div>
          <div className="row">
  <div className="col-md-1 ">
    <button className="btn btn-primary" type="submit">Save</button>
  </div>
  <div className="col-md-2 ">
    <button className="btn btn-primary" type="submit" onClick={() => printForm(values, "printandsave")}>Save Print</button>
  </div>
</div>
{isPopup == true ?
            <>

              <Modal show={true} animation={false} onHide={handleClosed} size="lg">
                <Modal.Header closeButton>
                  <Modal.Title>{billnumber}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  {billnumber == undefined ? ""
                    :
                    // <PrintTemplateAppointment pId={billnumber} handleSubmit={handleClosed} apppoinentDate={appointmentDate}/>}
                    <File billnumber={billnumber} handleSubmit={handleClosed} />}
                </Modal.Body>
              </Modal>
            </>
            :
            ""}
          <ToastContainer />
      </form>
    </div>
  )
}

export default AddPathologyReportForm