import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import API from "../../helper/axios";
import axios from "../../helper/axios";
import { useUser } from '../../Provider';
import PrintComponent from './PrintComponent'; // Import PrintComponent
import "./PathologyReport.css";
const File = (props) => {
    const { token } = useUser();
    const [data, setReportData] = useState([]);
    const [hospitalcodedata, setHospitalcodedata] = useState({});
    const [datas, setDatas] = useState([]);

    useEffect(() => {
        // Function to fetch pathology report data
        const getPathologyReport = async () => {
            try {
                const res = await axios.get(`/getpathologyreports/${props.billnumber}`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                if (res.status === 200) {
                    setReportData(res.data);
                    setHospitalcodedata(prevHospitalcodedata => ({
                        ...prevHospitalcodedata,
                        ...res.data[0]
                    }));
                } else {
                    alert(`Unexpected status code: ${res.status}`);
                }
            } catch (error) {
                // alert(`Data not found`);
            }
        };
        // Function to load hospital logo
        const loadHospitalLogo = async () => {
            if (hospitalcodedata.hospitalcode !== undefined) {
                try {
                    const res = await axios.get(`/gethospitallogo/${hospitalcodedata.hospitalcode}`, {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                        params: {
                            hospitalcode: hospitalcodedata.hospitalcode,
                        },
                    });
                    setDatas(res.data[0]);
                } catch (error) {
                    console.error(`Error loading hospital logo: ${error.message}`);
                }
            } else {
                console.warn('Hospital code is undefined. Skipping hospital logo loading.');
            }
        };

        const loadData = async () => {
            await getPathologyReport();
            if (hospitalcodedata.hospitalcode) {
                await loadHospitalLogo(hospitalcodedata.hospitalcode);
            }
        };
        loadData();
    }, [props.billnumber, token, hospitalcodedata.hospitalcode]);

    // JSX for common content (including hospital logo and details)
    const commonContent = (
        <div>
            {datas.attachment2 ? (
                <img
                    src={`${API.defaults.baseURL}/uploadimage/attachtwo/${datas.attachment2}`}
                    style={{ width: '100%', marginBottom: "1px", height: "20%" }}
                    alt="Attachment 2"
                />
            ) : (
                <div>
                    <img
                        src={`${API.defaults.baseURL}/uploadimage/hospitallogo/${datas.hospitallogo}`}
                        style={{ width: "15%", marginBottom: "5px", float: "left" }}
                        alt="Hospital Logo"
                    />
                    <h4 style={{ textAlign: "center" }}>
                        {datas.hospitalname ? datas.hospitalname.toUpperCase() : "HOSPITAL NAME NOT AVAILABLE"}
                    </h4>
                    <h6 style={{ textAlign: "center" }}>
                        {datas.address}
                    </h6>
                    <h6 style={{ textAlign: "center" }}>
                        Mobile: +91-{datas.phone}
                    </h6>
                </div>
            )}
        </div>
    );
    // JSX for headings
    const heading = (
        <table className="print-components">
            <thead>

                <tr>
                    <td><b>Bill Number:</b>{hospitalcodedata.billingno} </td>
                    <td><b>Mobile:</b>{hospitalcodedata.phoneno}</td>
                </tr>
                <tr>
                    <td><b>Booking Date:</b>{hospitalcodedata.createdon}</td>
                    <td><b>Name:</b>{hospitalcodedata.patientname}</td>
                </tr>
                <tr>
                    <td><b>Email:</b>{hospitalcodedata.email}</td>
                    <td><b>Address:</b>{hospitalcodedata.address}</td>
                </tr>
            </thead>
        </table>
    );
    // JSX for table headings
    const heading2 = (

        <table className="print-components">
            <thead>
                <tr>
                    <th >Test Name</th>
                    <th >Result</th>
                    <th >Unit</th>
                    <th >Bio. Ref. Range</th>
                    <th >Method</th>
                </tr>
            </thead>
        </table>

    );
    // Function to group data by category
    const groupDataByCategory = (data) => {
        const groupedData = {};

        data.forEach((item) => {
            if (!groupedData[item.categoryname]) {
                groupedData[item.categoryname] = [];
            }
            groupedData[item.categoryname].push(item);
        });

        return groupedData;
    };
    // Function to check if result is outside reference range
    const isOutsideReferenceRange = (result, referencerange) => {
        const [min, max] = referencerange.split('-').map(value => parseFloat(value.trim()));
        const resultValue = parseFloat(result);
        return isNaN(resultValue) || resultValue < min || resultValue > max;
    };

    const groupedData = groupDataByCategory(data);

    return (
        <div>
            <PrintComponent commonContent={commonContent} heading={heading} heading2={heading2}>
                <table className="print-components">
                    <tbody>
                        {Object.entries(groupedData).map(([category, categoryData], index) => (
                            <React.Fragment key={index}>
                                <tr>
                                    <td colSpan="12"><b>{category}</b></td>
                                </tr>
                                {categoryData.map((item, itemIndex) => (
                                    <tr key={itemIndex}>
                                        <td>{item.testparameter}</td>
                                        <td className={isOutsideReferenceRange(item.result, item.referencerange) ? 'bold-result' : ''}>{item.result}</td>
                                        <td>{item.unit}</td>
                                        <td>{item.referencerange}</td>
                                        <td>{item.method}</td>
                                    </tr>
                                ))}
                            </React.Fragment>
                        ))}
                    </tbody>
                </table>
            </PrintComponent>
        </div>
    );
};
export default File;
