import React from "react";
import { useFormik } from "formik";
import { useState, useEffect } from "react";
// import { empsignUpSchema } from "../../../helper/schemas";
import { Helmet } from "react-helmet";
import { useHistory } from "react-router-dom";
import { Table, Button, Modal, Form } from "react-bootstrap";
import axios from "../../../helper/axios";
import { userregistration } from "../../../helper/schemas";
import { SHA256 } from "crypto-js";
import * as Yup from "yup";  // Import Yup for validation
import { useUser } from "../../../Provider";

 const ValidationSchema = Yup.object({
  firstname: Yup.string().matches(/^[a-zA-Z\s]+$/, "First name should only contain letters").required("Please enter the first name"),
  lastname: Yup.string().matches(/^[a-zA-Z\s]+$/, "Last name should only contain letters").required("Please enter the last name"),
  email: Yup.string().email("Email must be a valid").required("Please enter the email"),
  //password: Yup.string().min(6, "Password must be at least 6 characters").required("Please enter the password"),
  password: Yup.string()
    .min(6, "Password must be at least 6 characters")
    .required("Please enter the password")
    .matches(
      /^(?=.*[A-Z])(?=.*[!@#$%^&*()_+])[A-Za-z\d!@#$%^&*()_+]{6,}$/,
      "Password must contain at least one uppercase letter and one special character"
    ),
  cpassword: Yup.string()
    .required("Please enter the confirm password")
    .oneOf([Yup.ref('password'), null], "Passwords must match"),
  gender: Yup.string().required("Please enter the gender"),
  role: Yup.string().required("Please select the role"),
  phoneno: Yup.string()
  .matches(/^[0-9]+$/, "Mobile Number should only contain numbers")
  .min(10, "Mobile Number must be at least 10 characters")
  .max(10, "Mobile Number must be at most 10 characters")
  .required("Mobile Number is required"),
  designation: Yup.string().required("Please select designation the gender"),
  city: Yup.string().matches(/^[a-zA-Z\s]+$/, "City should only contain letters"),
  state: Yup.string().matches(/^[a-zA-Z\s]+$/, "State should only contain letters"),
  // bloodgroup: Yup.string()
  // .matches(/^[a-zA-Z+\-]+$/, "Blood Group should only contain letters, +, or -")
  // .required("Please enter the blood group"),
  bloodgroup: Yup.string()
  .matches(/^[a-zA-Z+\- ]+$/, "Blood Group should only contain letters, +, -, or spaces"),
  hospitalcode: Yup.string().required("Please enter the hospital code"),
  doctorFee: Yup.string()
  .matches(/^[0-9]+$/, "Enter only number")
  .required("Please enter doctor fees")
  
});

const ValidationSchema1 = Yup.object({
  firstname: Yup.string().matches(/^[a-zA-Z\s]+$/, "First name should only contain letters").required("Please enter the first name"),
  lastname: Yup.string().matches(/^[a-zA-Z\s]+$/, "Last name should only contain letters").required("Please enter the last name"),
  email: Yup.string().email("Email must be a valid").required("Please enter the email"),
  //password: Yup.string().min(6, "Password must be at least 6 characters").required("Please enter the password"),
  password: Yup.string()
  .min(6, "Password must be at least 6 characters")
  .required("Please enter the password")
  .matches(
    /^(?=.*[A-Z])(?=.*[!@#$%^&*()_+])[A-Za-z\d!@#$%^&*()_+]{6,}$/,
    "Password must contain at least one uppercase letter and one special character"
  ),
  cpassword: Yup.string()
    .required("Please enter the confirm password")
    .oneOf([Yup.ref('password'), null], "Passwords must match"),
  gender: Yup.string().required("Please enter the gender"),
  role: Yup.string().required("Please select the role"),
  phoneno: Yup.string()
  .matches(/^[0-9]+$/, "Mobile Number should only contain numbers")
  .min(10, "Mobile Number must be at least 10 characters")
  .max(10, "Mobile Number must be at most 10 characters")
  .required("Mobile Number is required"),
  designation: Yup.string().required("Please select designation the gender"),
  city: Yup.string().matches(/^[a-zA-Z\s]+$/, "City should only contain letters"),
  state: Yup.string().matches(/^[a-zA-Z\s]+$/, "State should only contain letters"),
  // bloodgroup: Yup.string()
  // .matches(/^[a-zA-Z+\-]+$/, "Blood Group should only contain letters, +, or -")
  // .required("Please enter the blood group"),
  bloodgroup: Yup.string()
  .matches(/^[a-zA-Z+\- ]+$/, "Blood Group should only contain letters, +, -, or spaces"),
  // hospitalcode: Yup.string().required("Please enter the hospital code"),
  doctorFee: Yup.string()
  .matches(/^[0-9]+$/, "Enter only number")
  .required("Please enter doctor fees")
  
});


const initialValues = {
  firstname: "",
  lastname: "",
  email: "",
  password: "",
  dob: "",
  gender: "",
  maritalstatus: "",
  role: "",
  email: "",
  phoneno: "",
  bloodgroup: "",
  state: "",
  hospitalcode: "",
  city: "",
  cpassword: "",
  designation: "",
  doctorFee: 300,
 
};
// hashing password
const hashPassword = (password) => {
  return SHA256(password).toString();
};

const UserRegistration = () => {
  const {user, userHospitalcode, userrole1, isAuthRole,isAuthorized,gettokenId,token  } = useUser();
  const history = useHistory();
  const [message, setMessage] = useState("");
 

  // const handleSwitch = (event) => {
  //   const result = event.target.value.replace(/[^a-z]/gi, "");
  //   setMessage(result);
  // };
  let Showdate = new Date();
  let ShowTodaydate =
    Showdate.getFullYear() +
    "-" +
    (Showdate.getMonth() + 1) +
    "-" +
    Showdate.getDate();
  const [currentDate, setCurrentDate] = useState(ShowTodaydate);
  const [type, setType] = useState("password");
  const [icon, setIcon] = useState("show");
  // const token = localStorage.getItem("jwtToken");
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [selectedDesignation, setSelectedDesignation] = useState("");

  const handleToggle = (e) => {
    
    const gettype = e.target.value;
    console.log(gettype);
    if (gettype === "password") {
      setType("text");
      setIcon("Hide");
    } else {
      setType("password");
      setIcon("Show");
    }
  };



    // Function to determine which validation schema to use based on user role and hospital code
    const getValidationSchema = () => {
      if (userHospitalcode === 1) {
        return ValidationSchema;
      } else {
        return ValidationSchema1;
      }
    }


  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleReset,
    handleSubmit,
  } = useFormik({
    initialValues,
   validationSchema: getValidationSchema(), // Apply validation schema
    //creating new employ
    onSubmit: async (values, action) => {
        // values.password = cryptr.encrypt(values.password);
        // values.cpassword = cryptr.encrypt(values.cpassword);

        try{ 
         
        const data = new FormData();
         // hashing password.......................
         const hashedPassword = hashPassword(values.password);
         values.password = hashedPassword;
 
      data.append("document", JSON.stringify(values));
      data.append("currentDate", JSON.stringify(currentDate));
      data.append("gettokenId", JSON.stringify(gettokenId));
      data.append("userHospitalcode", JSON.stringify(userHospitalcode));
      data.append('hospitalcode', values.hospitalcode);
      console.log(JSON.stringify("jhdscvafdhgvhjdgfvjhfdv",userHospitalcode));
      console.log(JSON.stringify(values));

      console.log("________________222_________", userHospitalcode); // Add this line for debugging
      const response = await axios.post("/UserRegistration", data, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      });
      
      console.log(
        "🚀 ~ file: Registration.jsx ~ line 11 ~ Registration ~ values",
        values
      );

        if (response.status === 200) {
          return history.push("/listviewstaff");
        }
      
      }
    
      catch (error) {
        console.error(error);
        if (error.response && error.response.status === 400) {
          // Handle the case where a duplicate email is encountered
          alert("Email address is already in use. Please choose a different email");
        } else {
          // Handle other errors
          alert("Something went wrong: " + error.message);
        }
      }      
    },
  });


  useEffect(() => {
    // Your useEffect logic
    // ...
  }, [selectedDesignation]);

  
  const [data, setData] = useState([]);
  const [userRole, setRole] = useState();
  // const [gettokenId, setTokenId] = useState();
  // const [userHospitalcode, setUserHospitalcode] = useState();
  // const [userrole1, setUserrole1] = useState();
  // const [isAuthorized, setIsAuthorized] = useState(false);
  // Function to get login user's details
  // const loadAllDetails = async () => {
  //   try {
  //     const response = await axios.post('/loginuser', null, {
  //       headers: { Authorization: `Bearer ${token}` },
  //     });

  //     // Debugging: Log the entire response
  //     console.log("Response:", response);

  //     const UserRoles = response.data.person[0][0].role1;
  //     const hospitalcode = response.data.person[0][0].hospitalcode;

  //     // Debugging: Log the values of userHospitalcode and userrole1

  //     console.log("userHospitalcode:", hospitalcode);
  //     console.log("userrole1:", UserRoles);


  //     setUserHospitalcode(hospitalcode);
  //     setUserrole1(UserRoles);
  //     // Debugging: Log the condition result
  //     const authorizationResult = hospitalcode === 1 && UserRoles === 'Superadmin';
  //     console.log("Authorization Result:", authorizationResult);
  //     setIsAuthorized(authorizationResult);
  //     console.log("isAuthorized after setIsAuthorized:", isAuthorized);
  //   } catch (error) {
  //     console.error("Error:", error);

  //     if (error.response.status === 403) {
  //       alert('Token Expired. Please login again.');
  //       localStorage.removeItem('jwtToken');
  //       history.push('/');
  //     } else if (error.response.status === 401) {
  //       alert('Invalid token. Please login again.');
  //       localStorage.removeItem('jwtToken');
  //       history.push('/');
  //     } else {
  //       alert('Error: ' + error.response.data.error);
  //     }
  //   }
  // };
  //when page load or refresh happen this function will got called
  useEffect(() => {
    const loadData = async () => {
      // await loadAllDetails();
      //loadHospitalcode(); will wait for loadAllDetails(); to got execute
      await loadHospitalcode();
    };
    loadData();
  }, [userHospitalcode, userrole1, isAuthorized]);
   //get all hospital code
  const loadHospitalcode = async () => {
    try{
    //when userHospitalcode and userrole1 is not undefined then execute /gethospitalcode
    if (userHospitalcode !== undefined && userrole1 !== undefined) {
      const res = await axios.get("/gethospitalcode", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          hospitalcode: userHospitalcode, // Include the hospitalcode in the query parameters
          userrole: userrole1, // Include the userrole in the query parameters
        },
      });
      setData(res.data);
      console.log(res.data);
    }
  }catch(error){
       console.log("Error loading hospital code:", error);
  }
  };
  const currentDomain = window.location.hostname;
  return (
    <div className="p-3 pt-0 appointment">
       <Helmet>
        <title>Staff Registration | {currentDomain} | Hospital Management Software | Smart Solutions for Hospital Efficiency</title>
      </Helmet>
    <form onSubmit={handleSubmit}>
      <div class="fill header"><h3 class="float-left"> Staff Registration  </h3> </div>
      <div className="patient-new primary-info">
      <div className="row">
        <div className="col-md-12">
          <div className="row">
            <div className="col-md-6">
            <div className="col-md-12 mt-3">
              <div className="row">
              <div className="col-md-4">
              <label
                htmlFor="firstname"
                className="col-form-label leftone"
              >
                First Name
              </label>
            </div>
            <div className="col-md-8">
              <input
                type="text"
                autoComplete="off"
                name="firstname"
                id="firstname"
                placeholder="First Name"
                value={values.firstname}
                onChange={handleChange}
                onBlur={handleBlur}
                className="form-control ember-text-field text-left ember-view"
              />
              {errors.firstname && touched.firstname ? (
                <p className="form-erroremployee">{errors.firstname}</p>
              ) : null}
            </div>
            </div>
            </div>

        <div className="col-md-12 mt-3">
          <div className="row">
            <div className="col-md-4">
              <label
                htmlFor="email"
                className="col-form-label leftone"
              >
                Email
              </label>
            </div>
            <div className="col-md-8">
              <input
                rows="1"
                cols="30"
                type="text"
                autoComplete="off"
                name="email"
                id="email"
                placeholder="Email"
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
                className="form-control ember-text-field text-left ember-view"
              />
              {errors.email && touched.email ? (
                <p className="form-erroremployee">{errors.email}</p>
              ) : null}
            </div>
            </div>
            </div>

        <div className="col-md-12 mt-3">
              <div className="row">
                <div className="col-md-4">
                <label
                  htmlFor="gender"
                  className="col-form-label leftone"
                >
                  Gender
                </label>
              </div>
              <div className="col-md-8">
                <select
                  type="text"
                  autoComplete="off"
                  name="gender"
                  id="gender"
                  placeholder="Gender"
                  value={values.gender}
                  onChange={handleChange}
                  onBlur={handleBlur}

                  style={{ width: '73%', height: '32px', border: '1px solid #ced4da ', color: '#495057' }}
                >
                  <option>Select</option>
                  <option>Male</option>
                  <option>Female</option>
                  <option>Others</option>
                </select>
                {errors.gender && touched.gender ? (
                    <p className="form-erroremployee">{errors.gender}</p>
                  ) : null}
              </div>
              </div>
              </div>


         

            <div className="col-md-12 mt-3">
              <div className="row">
                <div className="col-md-4">
                <label
                  htmlFor="phoneno "
                  className="col-form-label leftone"
                >
                Mobile Number
                </label>
              </div>
              <div className="col-md-8">
                <input
                  type="number"
                  autoComplete="off"
                  name="phoneno"
                  id="phoneno"
                  placeholder="Mobile Number"
                  value={values.phoneno}
                  onChange={handleChange}
                  className="form-control ember-text-field text-left ember-view"
                />

                {errors.phoneno && touched.phoneno ? (
                  <p className="form-erroremployee">{errors.phoneno}</p>
                ) : null}
              </div>
          </div>
          </div> 

          <div className="col-md-12 mt-3">
              <div className="row">
                <div className="col-md-4">
              <label
                htmlFor="maritalstatus"
                className="col-form-label leftone"
              >
               Marital Status
              </label>
            </div>
            <div className="col-md-8">
              <select
                type="text"
                autoComplete="off"
                name="maritalstatus"
                id="maritalstatus"
                placeholder="maritalstatus"
                value={values.maritalstatus}
                onChange={handleChange}
                onBlur={handleBlur}

                style={{ width: '73%', height: '32px', border: '1px solid #ced4da ', color: '#495057' }}
              >
                <option>Select</option>
                <option>Married</option>
                <option>Single</option>
                <option>Divorced</option>
                <option>Widower</option>
              </select>
            </div>
            </div>
            </div>


             <div className="col-md-12 mt-3">
              <div className="row">
             <div className="col-md-4">
              <label
                htmlFor="dob"
                className="col-form-label"
              >
                Date of Birth
              </label>
            </div>
            <div className="col-md-8">
              <input
                type="date"
                autoComplete="off"
                name="dob"
                id="dob"
                placeholder="DOB"
                value={values.dob}
                onChange={handleChange}
                onBlur={handleBlur}
                className="form-control ember-text-field text-left ember-view"
              />

            </div>
            </div>
            </div>

            
        <div className="col-md-12 mt-3">
              <div className="row">
  <div className="col-md-4">
              <label
                htmlFor="city"
                className="col-form-label"
              >
                City
              </label>
            </div>
            <div className="col-md-8">
              <input
                type="text"
                autoComplete="off"
                name="city"
                id="city"
                placeholder="City"
                value={values.city}
                onChange={handleChange}
                onBlur={handleBlur}
                className="form-control ember-text-field text-left ember-view"
              ></input>
              {errors.city && touched.city ? (
                <p className="form-erroremployee">{errors.city}</p>
              ) : null}
            </div>
            </div>
            </div>
            
            
            <div className="col-md-12 mt-3">
              <div className="row">
                 {/* <div className="col-md-4">
                 <label
                    htmlFor="designation"
                    className="col-form-label"
                  >
               Designation
              </label>
            </div>
            <div className="col-md-8">
              <input
                type="text"
                autoComplete="off"
                name="designation"
                id="designation"
                placeholder="Designation"
                value={values.designation}
                onChange={handleChange}
                onBlur={handleBlur}
                className="form-control ember-text-field text-left ember-view"
              />
              
            </div> */}
              <div className="col-md-4">
              <label
                htmlFor="maritalstatus"
                className="col-form-label leftone"
              >
              Designation
              </label>
            </div>
            <div className="col-md-8">
            {userHospitalcode === 4 ? (   <select
                type="text"
                autoComplete="off"
                name="designation"
                id="designation"
                placeholder="Designation"
                value={values.designation}
                onChange={handleChange}
                onBlur={handleBlur}

                style={{ width: '73%', height: '32px', border: '1px solid #ced4da ', color: '#495057' }}
              >
                <option>Select</option>
                 <option>Nurse</option>
                 <option>Pharmacist</option>
                 <option>Pathologist</option>
                 <option>Receptionist</option>
               </select>
                ):(
                 <select
                 type="text"
                 autoComplete="off"
                 name="designation"
                 id="designation"
                 placeholder="Designation"
                 value={values.designation}
                 onChange={handleChange}
                 onBlur={handleBlur}
 
                 style={{ width: '73%', height: '32px', border: '1px solid #ced4da ', color: '#495057' }}
               >
                <option>Select</option>
                <option>Admin</option>
                <option>Manager </option>
                <option>Doctor</option>
                <option>Anesthetist</option>
                <option>OT Technician</option>
                <option>OT Assistant</option>
                <option>Nurse</option>
                <option>Compouder</option>
                <option>Accountant</option>
                <option>Pharmacist</option>
                <option>Pathologist</option>
                <option>Radiologist</option>
                <option>Receptionist</option>
              </select>
              )}
              {errors.designation && touched.designation ? (
                <p className="form-erroremployee">{errors.designation}</p>
              ) : null}
            </div>
          </div>
        </div>


          </div>

        
         


<div className="col-md-6">    
<div className="col-md-12 mt-3">
              <div className="row">
                <div className="col-md-4">
              <label
                htmlFor="lastname"
                className="col-form-label leftone"
              >
                Last Name
              </label>
            </div>
            <div className="col-md-8">
              <input
                type="text"
                autoComplete="off"
                name="lastname"
                id="lastname"
                placeholder="Last Name"
                value={values.lastname}
                onChange={handleChange}
                onBlur={handleBlur}
                className="form-control ember-text-field text-left ember-view"
              />

              {errors.lastname && touched.lastname ? (
                <p className="form-erroremployee">{errors.lastname}</p>
              ) : null}
            </div>
          </div>
        </div>


        <div className="col-md-12 mt-3">
              <div className="row">
                <div className="col-md-4">
              <label
                htmlFor="passward"
                className="col-form-label leftone"
              >
                Password
              </label>
            </div>
            <div className="col-md-6">
                <input
                  type={type}
                  autoComplete="off"
                  name="password"
                  id="password"
                  placeholder="Password"
                  value={values.password}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className="form-control ember-text-field text-left ember-view"
                />
        
                {errors.password && touched.password ? (
                  <p className="form-erroremployee">{errors.password}</p>
                ) : null}
               
        </div>
              <div className="col-md-2">
              <button
                className="show-button btn btn-primary"
                type="button"
                value={type}
                onClick={handleToggle}
              >
                Show
              </button>
                </div>
              
                </div>
              </div>


              <div className="col-md-12 mt-3">
              <div className="row">
                <div className="col-md-4">
              <label
                htmlFor="cpassword"
                className="col-form-label leftone"
              >
                Confirm Password
              </label>
              </div>
              <div className="col-md-8">
              <input
                  type={type}
                  autoComplete="off"
                  name="cpassword"
                  id="cpassword"
                  placeholder="Confirm Password"
                  value={values.cpassword}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className="form-control ember-text-field text-left ember-view"
                />
                {errors.cpassword && touched.cpassword ? (
                  <p className="form-erroremployee">{errors.cpassword}</p>
                ) : null}
              </div>

            </div>
          </div>


            <div className="col-md-12 mt-3">
              <div className="row">
              <div className="col-md-4">
              <label
                htmlFor="role"
                className="col-form-label leftone"
              >
                Role
              </label>
            </div>
            <div className="col-md-8">
               {isAuthorized ? (  <select
                  type="text"
                  autoComplete="off"
                  name="role"
                  id="role"
                  placeholder="Role"
                  value={values.role}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  style={{ width: '73%', height: '32px', border: '1px solid #ced4da ', color: '#495057' }}
                >
                  <option>Select</option>
                  <option>Superadmin </option>
                  <option>Admin </option>
                  <option>Employee</option>
                </select>
                 ) : (
                  <select
                  type="text"
                  autoComplete="off"
                  name="role"
                  id="role"
                  placeholder="Role"
                  value={values.role}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  style={{ width: '73%', height: '32px', border: '1px solid #ced4da ', color: '#495057' }}
                >
                  <option>Select</option>

                  <option>Admin </option>
                  <option>Employee</option>
                </select> )}
                {errors.role && touched.role ? (
                  <p className="form-erroremployee">{errors.role}</p>
                ) : null}
              </div>
          </div>
        </div>

        {isAuthorized ? (<div className="col-md-12 mt-3">
              <div className="row">
  
                <div className="col-md-4">
                  <label
                    htmlFor="hospitalcode"
                    className="col-form-label leftone"
                  >
                    Hospital Code
                  </label>
                </div>
                <div className="col-md-8">
                  {/* Conditionally render the dropdown and input field */}
                  {isAuthorized ? (
                    <select
                      type="text"
                      autoComplete="off"
                      name="hospitalcode"
                      id="hospitalcode"
                      placeholder="Hospital Code"
                      value={values.hospitalcode}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      style={{ width: '73%', height: '32px', border: '1px solid #ced4da', color: '#495057' }}
                    >
                      <option>Select</option>
                      {data.map((code) => (
                        <option value={code.hospitalcode} key={code.id}>
                          {code.hospitalcode}
                        </option>
                        
                      ))}
                    </select>
                    
                  ) : (
                    <input
                      type="hidden"
                      name="hospitalcode"
                      id="hospitalcode"
                      value={userHospitalcode}
                      disabled={true} // Set the input as disabled
                      className="form-control "
                    />
                    
                  )}
                  {isAuthorized && errors.hospitalcode && touched.hospitalcode ? (
                    <p className="form-erroremployee">{errors.hospitalcode}</p>
                  ) : null}
                </div></div></div>) : null}

        <div className="col-md-12 mt-3">
          <div className="row">
          <div className="col-md-4">
              <label
                htmlFor="bloodgroup"
                className="col-form-label "
              >
                Blood Group
              </label>
            </div>
            <div className="col-md-8">
              <select
                type="text"
                autoComplete="off"
                name="bloodgroup"
                id="bloodgroup"
                placeholder="Blood Group"
                value={values.bloodgroup}
                onChange={handleChange}
                onBlur={handleBlur}
                style={{ width: '73%', height: '32px', border: '1px solid #ced4da', color: '#495057' }}
              >
                <option>Select</option>
                  <option>O+ve</option>
                  <option>A+ve</option>
                  <option>B+ve</option>
                  <option>AB+ve</option>
                  <option>O-ve</option>
                  <option>A-ve</option>
                  <option>B-ve</option>
                  <option>AB-ve</option>
                </select>

              {errors.bloodgroup && touched.bloodgroup ? (
                <p className="form-erroremployee">{errors.bloodgroup}</p>
              ) : null}
            </div>
            </div>
            </div>
          
            <div className="col-md-12 mt-3">
              <div className="row">
              <div className="col-md-4">
              <label
                htmlFor="state"
                className="col-form-label"
              >
                State
              </label>
            </div>
            <div className="col-md-8">
              <input
                type="text"
                autoComplete="off"
                name="state"
                id="state"
                placeholder="State"
                value={values.state}
                onChange={handleChange}
                onBlur={handleBlur}
                className="form-control ember-text-field text-left ember-view"
              />
               {errors.state && touched.state ? (
                <p className="form-erroremployee">{errors.state}</p>
              ) : null}
            </div>
          </div>
        </div>

        {values.designation === "Doctor" && (
          <div className="col-md-12 mt-3">
            <div className="row">
              <div className="col-md-4">
                <label
                  htmlFor="doctorFee"
                  className="col-form-label leftone"
                >
                  Doctor Fee
                </label>
              </div>
              <div className="col-md-8">
                <input
                  type="text"
                  autoComplete="off"
                  name="doctorFee"
                  id="doctorFee"
                  placeholder="Doctor Fee"
                  value={values.doctorFee}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className="form-control ember-text-field text-left ember-view"
                />
                {errors.doctorFee && touched.doctorFee ? (
                  <p className="form-erroremployee">{errors.doctorFee}</p>
                ) : null}
              </div>
            </div>
          </div>
        )}


      </div>
      </div>
          </div>
          </div>
          </div>


      <div className="col-md-6  mt-3" >
        <Button
          type="submit"
          variant="success"
          className="mx-3"
          
        >
          Save
        </Button>
        <Button
          variant="primary"
          className="mx-3"
          onClick={handleReset}
          
        >
          Reset
        </Button>
      </div>
    </form>
  </div>
);
};

export default UserRegistration;

