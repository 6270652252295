import "./App.css";
import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import { Dashboard } from "./Pages/Dashboard/Dashboard";
import { Appointment, AppointmentDetailsedit, Appointmentedit, NewAppointment } from "./Pages/Appointment/Appointment";
import { AddPatientComponent, EditPatientlist, Listview, PatientEditComponent, PatientEditDetailsComponent, PatientRegistrationComponent } from "./Pages/Patient/Patient";
import { Loginpage, Loginpageforlogin } from "./Pages/Login/Loginpage";
import { AddBedComponent, AddBedGroupComponent, AddBedTypeComponent, AddFloorComponent, EditBedComponent, EditBedGroupComponent, EditBedTypeComponent, EditFloorComponent, ListViewBedComponent, ListViewBedGroupComponent, ListViewBedTypeComponent, ListViewFloorComponent } from "./Pages/Bed/Bed";
import { ChargesComponent, DetailChargesComponent, EditChargesComponent, NewChargesComponent } from "./Pages/Charges/Charges";
//import { HospitalComponent } from "./Pages/HospitalRegisrtation/HospitalComponent";
import { HospitalDetails, Hospitalistdetail, Hospitalists } from "./Hospital/HospitalDetails";
import { AddNewCharge, ChargesDetailsList } from "./Pages/AllCharges/AllCharges";
import { BillingListViewComponent, BillingPageComponent, CancelBillListComponent, EditBillingComponent } from "./Pages/Billing/Billing";
import ProtectedRoute from "./ProtectedRoute";
import PrivateRoute from "./PrivateRoute";
import { ListViewComponent, StaffEditComponent, StaffEditDetailsComponent, Staffreg } from "./Pages/Staff/Staff";
import { DischargedViewComponent, EditIPD, ListViewIPDComponent, OverViewComponent, PatientAdmitComponent } from "./Pages/IPDAppointment/IPD";
import { ReportAppointment, ReportBilling } from "./Pages/Reports/Reports";
import { AddBirthComponent, ListViewBirthComponent, UpdateBirthComponent } from "./Pages/Birth/Birth";
import { EditCategoryComponent, EditOperationComponent, ListViewCategoryComponent, ListViewOperationComponent, OperationCategoryComponent, OperationSetupComponent } from "./Pages/Operation/Operation";
import { AddMedicines, EditMedicineCategoryComponent, EditMedicineComponent, ListViewAddMedicines, ListViewMedicineChategory, MedicineChategory,AddMedicineUnitComponent,ListViewMedicineUnitComponent,EditMedicineUnitComponent,AddMedicineManufacturerComponent,ListViewMedicineManufacturerComponent,EditMedicineManufacturerComponent, ListViewMedicinesComponent, MedicineBillings, MedicineBillingList, PurchaseList, PurchaseMedicine, EditPurchaseComponent, EditPharmacyBillingDetails } from "./Pages/Medicine/Medicine";
import { AddRadiologyComponent, AddRadiologyUnits, EditRadiologyCategoryComponent, EditRadiologyParameter, EditRadiologyReportComponent, ListRadiologyReportComponent, ListViewAddRadiologyUnits, ListViewRadiologyChategories, ListViewRadiologyComponent, RadiologyChategory, RadiologyReportComponent } from "./Pages/Radiology/Radiology";
import { ListViewPathologyCategoryComponent,AddPathologyCategoryComponent,AddUnitComponent,ListViewUnitComponent,AddPathologyComponent,ListViewPathologyComponent, PathologyReportComponent, PathologyReportForm, EditPathologyReportComponent, EditPathologyCategory, EditPathologyUnit, EditPathologyParameter, } from "./Pages/Pathology/Pathology";
import ListviewRadiologyParameter from "./Pages/Radiology/RadiologyPages/ListviewRadiologyParameter";
import AddRadiologyParameter from "./Pages/Radiology/RadiologyPages/AddRadiologyParameter";
import Landingpage from "./LandingPage/Landingpage";
function App() {
  const [isAuthorized, setIsAuthorized] = useState(false);
  const [isAuthRole, setIsAuthRole] = useState(false);
  const [isAuthRole1, setIsAuthRole1] = useState(false);
  const [isAuthRole2, setIsAuthRole2] = useState(false);
  const [isAuthRole3, setIsAuthRole3] = useState(false);

console.log("+++++++++++++++++++++++++++++++++++++",isAuthRole1)
  const handleUpdateAuthorizationStatus = (authorizationStatus) => {
    setIsAuthorized(authorizationStatus);
  };
  const handleUpdateAuthorization = (authorization) => {
    setIsAuthRole(authorization);
  };

  const handleUpdateAuthorization1 = (authorization) => {
    setIsAuthRole1(authorization);
  };


  
  const handleUpdateAuthorization2 = (authorization) => {
    setIsAuthRole2(authorization);
  };

  const handleUpdateAuthorization3 = (authorization) => {
    setIsAuthRole3(authorization);
  };
  return (
    <Router>
      <Switch>
        <Route
          path="/dashboard"
          exact
          component={(props) => <ProtectedRoute {...props} component={Dashboard} />}
        />

    <PrivateRoute
          path="/appointment"
          exact
          onUpdateAuthorizationStatus={handleUpdateAuthorizationStatus}
          onUpdateAuthorization={handleUpdateAuthorization}
          onUpdateAuthorization1={handleUpdateAuthorization1}
          onUpdateAuthorization2={handleUpdateAuthorization2}
          onUpdateAuthorization3={handleUpdateAuthorization3}
          component={(props) => {
            // Check if the user is authorized and render Hospitalists or show Unauthorized Access
            console.log(props);
            if (isAuthorized || isAuthRole || isAuthRole3) {
              return <Appointment />;
            } else {
              return;
            }
          }}
        />

        {/* <Route
          path="/appointment"
          exact
          component={(props) => <ProtectedRoute {...props} component={Appointment} />}
        /> */}


       {/* <PrivateRoute
          path="/rescheduleappointment/:opdnumber"
          exact
          onUpdateAuthorizationStatus={handleUpdateAuthorizationStatus}
          onUpdateAuthorization={handleUpdateAuthorization}
          onUpdateAuthorization1={handleUpdateAuthorization1}
          onUpdateAuthorization2={handleUpdateAuthorization2}
          component={(props) => {
            // Check if the user is authorized and render Hospitalists or show Unauthorized Access
            console.log(props);
            if (isAuthorized || isAuthRole) {
              return <Appointmentedit />;
            } else {
              return;
            }
          }}
        /> */}


        <Route
          path="/rescheduleappointment/:opdnumber"
          exact
          component={(props) => <ProtectedRoute {...props} component={Appointmentedit} />}
        />


{/* <PrivateRoute
          path="/editappointment/:opdnumber"
          exact
          onUpdateAuthorizationStatus={handleUpdateAuthorizationStatus}
          onUpdateAuthorization={handleUpdateAuthorization}
          onUpdateAuthorization1={handleUpdateAuthorization1}
          onUpdateAuthorization2={handleUpdateAuthorization2}
          component={(props) => {
            // Check if the user is authorized and render Hospitalists or show Unauthorized Access
            console.log(props);
            if (isAuthorized || isAuthRole) {
              return <AppointmentDetailsedit />;
            } else {
              return;
            }
          }}
        /> */}


        <Route
          path="/editappointment/:opdnumber"
          exact
          component={(props) => <ProtectedRoute {...props} component={AppointmentDetailsedit} />}
        />


<PrivateRoute
          path="/newappointment"
          exact
          onUpdateAuthorizationStatus={handleUpdateAuthorizationStatus}
          onUpdateAuthorization={handleUpdateAuthorization}
          onUpdateAuthorization1={handleUpdateAuthorization1}
          onUpdateAuthorization2={handleUpdateAuthorization2}
          onUpdateAuthorization3={handleUpdateAuthorization3}
          component={(props) => {
            // Check if the user is authorized and render Hospitalists or show Unauthorized Access
            console.log(props);
            if (isAuthorized || isAuthRole) {
              return <NewAppointment />;
            } else {
              return;
            }
          }}
        />


{/* 
        <Route
          path="/newappointment"
          exact
          component={(props) => <ProtectedRoute {...props} component={NewAppointment} />}
        /> */}


<PrivateRoute
          path="/addoperation"
          exact
          onUpdateAuthorizationStatus={handleUpdateAuthorizationStatus}
          onUpdateAuthorization={handleUpdateAuthorization}
          onUpdateAuthorization1={handleUpdateAuthorization1}
          onUpdateAuthorization2={handleUpdateAuthorization2}
          onUpdateAuthorization3={handleUpdateAuthorization3}
          component={(props) => {
            // Check if the user is authorized and render Hospitalists or show Unauthorized Access
            console.log(props);
            if (isAuthorized || isAuthRole) {
              return <OperationSetupComponent />;
            } else {
              return;
            }
          }}
        />


{/* <Route
          path="/addoperation"
          exact
          component={(props) => <ProtectedRoute {...props} component={OperationSetupComponent} />}
        /> */}


<PrivateRoute
          path="/listviewoperations"
          exact
          onUpdateAuthorizationStatus={handleUpdateAuthorizationStatus}
          onUpdateAuthorization={handleUpdateAuthorization}
          onUpdateAuthorization1={handleUpdateAuthorization1}
          onUpdateAuthorization2={handleUpdateAuthorization2}
          onUpdateAuthorization3={handleUpdateAuthorization3}
          component={(props) => {
            // Check if the user is authorized and render Hospitalists or show Unauthorized Access
            console.log(props);
            if (isAuthorized || isAuthRole) {
              return <ListViewOperationComponent />;
            } else {
              return;
            }
          }}
        />

            {/* <Route
          path="/listviewoperations"
          exact
          component={(props) => <ProtectedRoute {...props} component={ListViewOperationComponent} />}
        /> */}


<PrivateRoute
          path="/listviewcategories"
          exact
          onUpdateAuthorizationStatus={handleUpdateAuthorizationStatus}
          onUpdateAuthorization={handleUpdateAuthorization}
          onUpdateAuthorization1={handleUpdateAuthorization1}
          onUpdateAuthorization2={handleUpdateAuthorization2}
          onUpdateAuthorization3={handleUpdateAuthorization3}
          component={(props) => {
            // Check if the user is authorized and render Hospitalists or show Unauthorized Access
            console.log(props);
            if (isAuthorized || isAuthRole) {
              return <ListViewCategoryComponent />;
            } else {
              return;
            }
          }}
        />


  {/* <Route
          path="/listviewcategories"
          exact
          component={(props) => <ProtectedRoute {...props} component={ListViewCategoryComponent} />}
        /> */}


{/* <PrivateRoute
          path="/editcategory/:categoryid"
          exact
          onUpdateAuthorizationStatus={handleUpdateAuthorizationStatus}
          onUpdateAuthorization={handleUpdateAuthorization}
          onUpdateAuthorization1={handleUpdateAuthorization1}
          onUpdateAuthorization2={handleUpdateAuthorization2}
          component={(props) => {
            // Check if the user is authorized and render Hospitalists or show Unauthorized Access
            console.log(props);
            if (isAuthorized || isAuthRole) {
              return <EditCategoryComponent />;
            } else {
              return;
            }
          }}
        /> */}


         <Route
          path="/editcategory/:categoryid"
          exact
          component={(props) => <ProtectedRoute {...props} component={EditCategoryComponent} />}
        />

{/* <PrivateRoute
          path="/editoperation/:operationsetupid"
          exact
          onUpdateAuthorizationStatus={handleUpdateAuthorizationStatus}
          onUpdateAuthorization={handleUpdateAuthorization}
          onUpdateAuthorization1={handleUpdateAuthorization1}
          onUpdateAuthorization2={handleUpdateAuthorization2}
          component={(props) => {
            // Check if the user is authorized and render Hospitalists or show Unauthorized Access
            console.log(props);
            if (isAuthorized || isAuthRole) {
              return <EditOperationComponent />;
            } else {
              return;
            }
          }}
        /> */}


    <Route
          path="/editoperation/:operationsetupid"
          exact
          component={(props) => <ProtectedRoute {...props} component={EditOperationComponent} />}
        />


      <PrivateRoute
          path="/addcategory"
          exact
          onUpdateAuthorizationStatus={handleUpdateAuthorizationStatus}
          onUpdateAuthorization={handleUpdateAuthorization}
          onUpdateAuthorization1={handleUpdateAuthorization1}
          onUpdateAuthorization2={handleUpdateAuthorization2}
          onUpdateAuthorization3={handleUpdateAuthorization3}
          component={(props) => {
            // Check if the user is authorized and render Hospitalists or show Unauthorized Access
            console.log(props);
            if (isAuthorized || isAuthRole) {
              return <OperationCategoryComponent />;
            } else {
              return;
            }
          }}
        />


         {/* <Route
          path="/addcategory"
          exact
          component={(props) => <ProtectedRoute {...props} component={OperationCategoryComponent} />}
        /> */}


<PrivateRoute
          path="/addbirth"
          exact
          onUpdateAuthorizationStatus={handleUpdateAuthorizationStatus}
          onUpdateAuthorization={handleUpdateAuthorization}
          onUpdateAuthorization1={handleUpdateAuthorization1}
          onUpdateAuthorization2={handleUpdateAuthorization2}
          onUpdateAuthorization3={handleUpdateAuthorization3}
          component={(props) => {
            // Check if the user is authorized and render Hospitalists or show Unauthorized Access
            console.log(props);
            if (isAuthorized || isAuthRole) {
              return <AddBirthComponent />;
            } else {
              return;
            }
          }}
        />

       {/* <Route
          path="/addbirth"
          exact
          component={(props) => <ProtectedRoute {...props} component={AddBirthComponent} />}
        /> */}

<PrivateRoute
          path="/listviewbirth"
          exact
          onUpdateAuthorizationStatus={handleUpdateAuthorizationStatus}
          onUpdateAuthorization={handleUpdateAuthorization}
          onUpdateAuthorization1={handleUpdateAuthorization1}
          onUpdateAuthorization2={handleUpdateAuthorization2}
          onUpdateAuthorization3={handleUpdateAuthorization3}
          component={(props) => {
            // Check if the user is authorized and render Hospitalists or show Unauthorized Access
            console.log(props);
            if (isAuthorized || isAuthRole) {
              return <ListViewBirthComponent />;
            } else {
              return;
            }
          }}
        />

       {/* <Route
          path="/listviewbirth"
          exact
          component={(props) => <ProtectedRoute {...props} component={ListViewBirthComponent} />}
        /> */}

{/* <PrivateRoute
          path="/updatebirthrecord/:birthnumber"
          exact
          onUpdateAuthorizationStatus={handleUpdateAuthorizationStatus}
          onUpdateAuthorization={handleUpdateAuthorization}
          onUpdateAuthorization1={handleUpdateAuthorization1}
          onUpdateAuthorization2={handleUpdateAuthorization2}
          component={(props) => {
            // Check if the user is authorized and render Hospitalists or show Unauthorized Access
            console.log(props);
            if (isAuthorized || isAuthRole) {
              return <UpdateBirthComponent />;
            } else {
              return;
            }
          }}
        /> */}

          <Route
          path="/updatebirthrecord/:birthnumber"
          exact
          component={(props) => <ProtectedRoute {...props} component={UpdateBirthComponent} />}
        />


{/* <PrivateRoute
          path="/editbedgroup/:bedgroupid"
          exact
          onUpdateAuthorizationStatus={handleUpdateAuthorizationStatus}
          onUpdateAuthorization={handleUpdateAuthorization}
          onUpdateAuthorization1={handleUpdateAuthorization1}
          onUpdateAuthorization2={handleUpdateAuthorization2}
          component={(props) => {
            // Check if the user is authorized and render Hospitalists or show Unauthorized Access
            console.log(props);
            if (isAuthorized || isAuthRole) {
              return <EditBedGroupComponent />;
            } else {
              return;
            }
          }}
        /> */}

       <Route
          path="/editbedgroup/:bedgroupid"
          exact
          component={(props) => <ProtectedRoute {...props} component={EditBedGroupComponent} />}
        />   



{/* <PrivateRoute
          path="/editfloor/:floorid"
          exact
          onUpdateAuthorizationStatus={handleUpdateAuthorizationStatus}
          onUpdateAuthorization={handleUpdateAuthorization}
          onUpdateAuthorization1={handleUpdateAuthorization1}
          onUpdateAuthorization2={handleUpdateAuthorization2}
          component={(props) => {
            // Check if the user is authorized and render Hospitalists or show Unauthorized Access
            console.log(props);
            if (isAuthorized || isAuthRole) {
              return <EditFloorComponent />;
            } else {
              return;
            }
          }}
        /> */}

    <Route
          path="/editfloor/:floorid"
          exact
          component={(props) => <ProtectedRoute {...props} component={EditFloorComponent} />}
        />   


  {/* <PrivateRoute
            path="/editbed/:bedid"
            exact
            onUpdateAuthorizationStatus={handleUpdateAuthorizationStatus}
            onUpdateAuthorization={handleUpdateAuthorization}
            onUpdateAuthorization1={handleUpdateAuthorization1}
            onUpdateAuthorization2={handleUpdateAuthorization2}
            component={(props) => {
              // Check if the user is authorized and render Hospitalists or show Unauthorized Access
              console.log(props);
              if (isAuthorized || isAuthRole) {
                return <EditBedComponent />;
              } else {
                return;
              }
            }}
          /> */}




    <Route
          path="/editbed/:bedid"
          exact
          component={(props) => <ProtectedRoute {...props} component={EditBedComponent} />}
        />   

{/* <PrivateRoute
          path="/editbedtype/:bedtypeid"
          exact
          onUpdateAuthorizationStatus={handleUpdateAuthorizationStatus}
          onUpdateAuthorization={handleUpdateAuthorization}
          onUpdateAuthorization1={handleUpdateAuthorization1}
          onUpdateAuthorization2={handleUpdateAuthorization2}
          component={(props) => {
            // Check if the user is authorized and render Hospitalists or show Unauthorized Access
            console.log(props);
            if (isAuthorized || isAuthRole) {
              return <EditBedTypeComponent />;
            } else {
              return;
            }
          }}
        />   */}

<Route
          path="/editbedtype/:bedtypeid"
          exact
          component={(props) => <ProtectedRoute {...props} component={EditBedTypeComponent} />}
        />


   <PrivateRoute
          path="/addbed"
          exact
          onUpdateAuthorizationStatus={handleUpdateAuthorizationStatus}
          onUpdateAuthorization={handleUpdateAuthorization}
          onUpdateAuthorization1={handleUpdateAuthorization1}
          onUpdateAuthorization2={handleUpdateAuthorization2}
          onUpdateAuthorization3={handleUpdateAuthorization3}
          component={(props) => {
            // Check if the user is authorized and render Hospitalists or show Unauthorized Access
            console.log(props);
            if (isAuthorized || isAuthRole) {
              return <AddBedComponent />;
            } else {
              return;
            }
          }}
        />

        {/* <Route
          path="/addbed"
          exact
          component={(props) => <ProtectedRoute {...props} component={AddBedComponent} />}
        /> */}

<PrivateRoute
          path="/listviewfloor"
          exact
          onUpdateAuthorizationStatus={handleUpdateAuthorizationStatus}
          onUpdateAuthorization={handleUpdateAuthorization}
          onUpdateAuthorization1={handleUpdateAuthorization1}
          onUpdateAuthorization2={handleUpdateAuthorization2}
          onUpdateAuthorization3={handleUpdateAuthorization3}
          component={(props) => {
            // Check if the user is authorized and render Hospitalists or show Unauthorized Access
            console.log(props);
            if (isAuthorized || isAuthRole) {
              return <ListViewFloorComponent />;
            } else {
              return;
            }
          }}
        /> 

        {/* <Route
          path="/listviewfloor"
          exact
          component={(props) => <ProtectedRoute {...props} component={ListViewFloorComponent} />}
        /> */}

<PrivateRoute
          path="/addfloor"
          exact
          onUpdateAuthorizationStatus={handleUpdateAuthorizationStatus}
          onUpdateAuthorization={handleUpdateAuthorization}
          onUpdateAuthorization1={handleUpdateAuthorization1}
          onUpdateAuthorization2={handleUpdateAuthorization2}
          onUpdateAuthorization3={handleUpdateAuthorization3}
          component={(props) => {
            // Check if the user is authorized and render Hospitalists or show Unauthorized Access
            console.log(props);
            if (isAuthorized || isAuthRole) {
              return <AddFloorComponent />;
            } else {
              return;
            }
          }}
        /> 

        {/* <Route
          path="/addfloor"
          exact
          component={(props) => <ProtectedRoute {...props} component={AddFloorComponent} />}
        /> */}

<PrivateRoute
          path="/addbedgroup"
          exact
          onUpdateAuthorizationStatus={handleUpdateAuthorizationStatus}
          onUpdateAuthorization={handleUpdateAuthorization}
          onUpdateAuthorization1={handleUpdateAuthorization1}
          onUpdateAuthorization2={handleUpdateAuthorization2}
          onUpdateAuthorization3={handleUpdateAuthorization3}
          component={(props) => {
            // Check if the user is authorized and render Hospitalists or show Unauthorized Access
            console.log(props);
            if (isAuthorized || isAuthRole) {
              return <AddBedGroupComponent />;
            } else {
              return;
            }
          }}
        /> 

        {/* <Route
          path="/addbedgroup"
          exact
          component={(props) => <ProtectedRoute {...props} component={AddBedGroupComponent} />}
        /> */}


<PrivateRoute
          path="/listviewbedgroup"
          exact
          onUpdateAuthorizationStatus={handleUpdateAuthorizationStatus}
          onUpdateAuthorization={handleUpdateAuthorization}
          onUpdateAuthorization1={handleUpdateAuthorization1}
          onUpdateAuthorization2={handleUpdateAuthorization2}
          onUpdateAuthorization3={handleUpdateAuthorization3}
          component={(props) => {
            // Check if the user is authorized and render Hospitalists or show Unauthorized Access
            console.log(props);
            if (isAuthorized || isAuthRole) {
              return <ListViewBedGroupComponent />;
            } else {
              return;
            }
          }}
        /> 


        {/* <Route
          path="/listviewbedgroup"
          exact
          component={(props) => <ProtectedRoute {...props} component={ListViewBedGroupComponent} />}
        /> */}


<PrivateRoute
          path="/addbedtype"
          exact
          onUpdateAuthorizationStatus={handleUpdateAuthorizationStatus}
          onUpdateAuthorization={handleUpdateAuthorization}
          onUpdateAuthorization1={handleUpdateAuthorization1}
          onUpdateAuthorization2={handleUpdateAuthorization2}
          onUpdateAuthorization3={handleUpdateAuthorization3}
          component={(props) => {
            // Check if the user is authorized and render Hospitalists or show Unauthorized Access
            console.log(props);
            if (isAuthorized || isAuthRole) {
              return <AddBedTypeComponent />;
            } else {
              return;
            }
          }}
        /> 

        {/* <Route
          path="/addbedtype"
          exact
          component={(props) => <ProtectedRoute {...props} component={AddBedTypeComponent} />}
        /> */}


<PrivateRoute
          path="/listviewbedtype"
          exact
          onUpdateAuthorizationStatus={handleUpdateAuthorizationStatus}
          onUpdateAuthorization={handleUpdateAuthorization}
          onUpdateAuthorization1={handleUpdateAuthorization1}
          onUpdateAuthorization2={handleUpdateAuthorization2}
          onUpdateAuthorization3={handleUpdateAuthorization3}
          component={(props) => {
            // Check if the user is authorized and render Hospitalists or show Unauthorized Access
            console.log(props);
            if (isAuthorized || isAuthRole) {
              return <ListViewBedTypeComponent />;
            } else {
              return;
            }
          }}
        /> 

        {/* <Route
          path="/listviewbedtype"
          exact
          component={(props) => <ProtectedRoute {...props} component={ListViewBedTypeComponent} />}
        /> */}



<PrivateRoute
          path="/listviewIPD"
          exact
          onUpdateAuthorizationStatus={handleUpdateAuthorizationStatus}
          onUpdateAuthorization={handleUpdateAuthorization}
          onUpdateAuthorization1={handleUpdateAuthorization1}
          onUpdateAuthorization2={handleUpdateAuthorization2}
          onUpdateAuthorization3={handleUpdateAuthorization3}
          component={(props) => {
            // Check if the user is authorized and render Hospitalists or show Unauthorized Access
            console.log(props);
            if (isAuthorized || isAuthRole || isAuthRole3 ) {
              return <ListViewIPDComponent />;
            } else {
              return;
            }
          }}
        /> 


        {/* <Route
          path="/listviewIPD"
          exact
          component={(props) => <ProtectedRoute {...props} component={ListViewIPDComponent} />}
        /> */}

        
<PrivateRoute
          path="/dischargedview"
          exact
          onUpdateAuthorizationStatus={handleUpdateAuthorizationStatus}
          onUpdateAuthorization={handleUpdateAuthorization}
          onUpdateAuthorization1={handleUpdateAuthorization1}
          onUpdateAuthorization2={handleUpdateAuthorization2}
          onUpdateAuthorization3={handleUpdateAuthorization3}
          component={(props) => {
            // Check if the user is authorized and render Hospitalists or show Unauthorized Access
            console.log(props);
            if (isAuthorized || isAuthRole) {
              return <DischargedViewComponent />;
            } else {
              return;
            }
          }}
        /> 



       {/* <Route
          path="/dischargedview"
          exact
          component={(props) => <ProtectedRoute {...props} component={DischargedViewComponent} />}
        /> */}


{/* <PrivateRoute
          path="/editipdpatientadmit/:admitnumber"
          exact
          onUpdateAuthorizationStatus={handleUpdateAuthorizationStatus}
          onUpdateAuthorization={handleUpdateAuthorization}
          onUpdateAuthorization1={handleUpdateAuthorization1}
          onUpdateAuthorization2={handleUpdateAuthorization2}
          component={(props) => {
            // Check if the user is authorized and render Hospitalists or show Unauthorized Access
            console.log(props);
            if (isAuthorized || isAuthRole) {
              return <OverViewComponent />;
            } else {
              return;
            }
          }}
        /> */}


   <Route
           path="/editipdpatientadmit/:admitnumber"
          exact
          component={(props) => <ProtectedRoute {...props} component={OverViewComponent} />}
        />


<PrivateRoute
          path="/patientadmit"
          exact
          onUpdateAuthorizationStatus={handleUpdateAuthorizationStatus}
          onUpdateAuthorization={handleUpdateAuthorization}
          onUpdateAuthorization1={handleUpdateAuthorization1}
          onUpdateAuthorization2={handleUpdateAuthorization2}
          onUpdateAuthorization3={handleUpdateAuthorization3}
          component={(props) => {
            // Check if the user is authorized and render Hospitalists or show Unauthorized Access
            console.log(props);
            if (isAuthorized || isAuthRole) {
              return <PatientAdmitComponent />;
            } else {
              return;
            }
          }}
        />


        {/* <Route
          path="/patientadmit"
          exact
          component={(props) => <ProtectedRoute {...props} component={PatientAdmitComponent} />}
        /> */}

        {/* <Route
          path="/editipdpatientadmit/:admitnumber"
          exact
          component={(props) => <ProtectedRoute {...props} component={EditIPD} />}
        /> */}


       <Route
          path="/"
          exact
          component={Landingpage}
        />    


        <Route
          path="/login"
          exact 
          component={Loginpageforlogin}
        />
        <PrivateRoute
          path="/userregistration"
          exact
          onUpdateAuthorizationStatus={handleUpdateAuthorizationStatus}
          onUpdateAuthorization={handleUpdateAuthorization}
          onUpdateAuthorization1={handleUpdateAuthorization1}
          onUpdateAuthorization2={handleUpdateAuthorization2}
          onUpdateAuthorization3={handleUpdateAuthorization3}
          component={(props) => {
            // Check if the user is authorized and render Hospitalists or show Unauthorized Access
            console.log(props);
            if (isAuthorized || isAuthRole) {
              return <Staffreg />;
            } else {
              return;
            }
          }}
        />
        {/* <Route
          path="/userregistration"
          exact
          component={(props) => <ProtectedRoute {...props} component={Staffreg} />}
        /> */}

<PrivateRoute
          path="/newpatientregistration"
          exact
          onUpdateAuthorizationStatus={handleUpdateAuthorizationStatus}
          onUpdateAuthorization={handleUpdateAuthorization}
          onUpdateAuthorization1={handleUpdateAuthorization1}
          onUpdateAuthorization2={handleUpdateAuthorization2}
          onUpdateAuthorization3={handleUpdateAuthorization3}
          component={(props) => {
            // Check if the user is authorized and render Hospitalists or show Unauthorized Access
            console.log(props);
            if (isAuthorized || isAuthRole || isAuthRole1) {
              return <PatientRegistrationComponent />;
            } else {
              return;
            }
          }}
        />

        {/* <Route
          path="/newpatientregistration"
          exact
          component={(props) => <ProtectedRoute {...props} component={PatientRegistrationComponent} />}
        /> */}


<PrivateRoute
          path="/listviewbed"
          exact
          onUpdateAuthorizationStatus={handleUpdateAuthorizationStatus}
          onUpdateAuthorization={handleUpdateAuthorization}
          onUpdateAuthorization1={handleUpdateAuthorization1}
          onUpdateAuthorization2={handleUpdateAuthorization2}
          onUpdateAuthorization3={handleUpdateAuthorization3}
          component={(props) => {
            // Check if the user is authorized and render Hospitalists or show Unauthorized Access
            console.log(props);
            if (isAuthorized || isAuthRole) {
              return <ListViewBedComponent />;
            } else {
              return;
            }
          }}
        />

        {/* <Route
          path="/listviewbed"
          exact
          component={(props) => <ProtectedRoute {...props} component={ListViewBedComponent} />}
        /> */}

        {/* <Route
          path="/addpatient"
          exact
          component={AddPatientComponent}
        /> */}


      <PrivateRoute
          path="/allcharges"
          exact
          onUpdateAuthorizationStatus={handleUpdateAuthorizationStatus}
          onUpdateAuthorization={handleUpdateAuthorization}
          onUpdateAuthorization1={handleUpdateAuthorization1}
          onUpdateAuthorization2={handleUpdateAuthorization2}
          onUpdateAuthorization3={handleUpdateAuthorization3}
          component={(props) => {
            // Check if the user is authorized and render Hospitalists or show Unauthorized Access
            console.log(props);
            if (isAuthorized || isAuthRole) {
              return <ChargesComponent />;
            } else {
              return;
            }
          }}
        />

{/* 
        <Route
          path="/allcharges"
          exact
          component={(props) => <ProtectedRoute {...props} component={ChargesComponent} />}
        /> */}



<PrivateRoute
          path="/createcharges"
          exact
          onUpdateAuthorizationStatus={handleUpdateAuthorizationStatus}
          onUpdateAuthorization={handleUpdateAuthorization}
          onUpdateAuthorization1={handleUpdateAuthorization1}
          onUpdateAuthorization2={handleUpdateAuthorization2}
          onUpdateAuthorization3={handleUpdateAuthorization3}
          component={(props) => {
            // Check if the user is authorized and render Hospitalists or show Unauthorized Access
            console.log(props);
            if (isAuthorized || isAuthRole) {
              return <NewChargesComponent />;
            } else {
              return;
            }
          }}
        />


        {/* <Route
          path="/createcharges"
          exact
          component={(props) => <ProtectedRoute {...props} component={NewChargesComponent} />}
        /> */}

{/* 
<PrivateRoute
          path="/editcharges/:chargecode/edit"
          exact
          onUpdateAuthorizationStatus={handleUpdateAuthorizationStatus}
          onUpdateAuthorization={handleUpdateAuthorization}
          onUpdateAuthorization1={handleUpdateAuthorization1}
          onUpdateAuthorization2={handleUpdateAuthorization2}
          component={(props) => {
            // Check if the user is authorized and render Hospitalists or show Unauthorized Access
            console.log(props);
            if (isAuthorized || isAuthRole) {
              return <EditChargesComponent />;
            } else {
              return;
            }
          }}
        /> */}

        <Route
          path="/editcharges/:chargecode/edit"
          exact
          component={(props) => <ProtectedRoute {...props} component={EditChargesComponent} />}
        />

{/* <PrivateRoute
          path="/editcharges/:chargecode"
          exact
          onUpdateAuthorizationStatus={handleUpdateAuthorizationStatus}
          onUpdateAuthorization={handleUpdateAuthorization}
          onUpdateAuthorization1={handleUpdateAuthorization1}
          onUpdateAuthorization2={handleUpdateAuthorization2}
          component={(props) => {
            // Check if the user is authorized and render Hospitalists or show Unauthorized Access
            console.log(props);
            if (isAuthorized || isAuthRole) {
              return <DetailChargesComponent />;
            } else {
              return;
            }
          }}
        /> */}

        <Route
          path="/editcharges/:chargecode"
          exact
          component={(props) => <ProtectedRoute {...props} component={DetailChargesComponent} />}
        />


        {/* <Route
          path="/hospitalregistration"
          exact
          component={HospitalComponent}
        /> */}
        {/* <Route
          path="/listviewpatients"
          exact
          component={(props) => <ProtectedRoute {...props} component={Listview} />}
        /> */}


        <PrivateRoute
          path="/listviewpatients"
          exact
          onUpdateAuthorizationStatus={handleUpdateAuthorizationStatus}
          onUpdateAuthorization={handleUpdateAuthorization}
          onUpdateAuthorization1={handleUpdateAuthorization1}
          onUpdateAuthorization2={handleUpdateAuthorization2}
          onUpdateAuthorization3={handleUpdateAuthorization3}
          component={(props) => {
            // Check if the user is authorized and render Hospitalists or show Unauthorized Access
            console.log(props);
            if (isAuthorized || isAuthRole || isAuthRole1) {
              return <Listview />;
            } else {
              return;
            }
          }}
        />


{/* <PrivateRoute
          path="/listviewpatients/:patientid"
          exact
          onUpdateAuthorizationStatus={handleUpdateAuthorizationStatus}
          onUpdateAuthorization={handleUpdateAuthorization}
          onUpdateAuthorization1={handleUpdateAuthorization1}
          onUpdateAuthorization2={handleUpdateAuthorization2}
          component={(props) => {
            // Check if the user is authorized and render Hospitalists or show Unauthorized Access
            console.log(props);
            if (isAuthorized || isAuthRole) {
              return <PatientEditComponent />;
            } else {
              return;
            }
          }}
        /> */}


        <Route
          path="/listviewpatients/:patientid"
          exact
          component={(props) => <ProtectedRoute {...props} component={PatientEditComponent} />}
        />


{/* <PrivateRoute
          path="/listviewpatients/:patientid/edit"
          exact
          onUpdateAuthorizationStatus={handleUpdateAuthorizationStatus}
          onUpdateAuthorization={handleUpdateAuthorization}
          onUpdateAuthorization1={handleUpdateAuthorization1}
          onUpdateAuthorization2={handleUpdateAuthorization2}
          component={(props) => {
            // Check if the user is authorized and render Hospitalists or show Unauthorized Access
            console.log(props);
            if (isAuthorized || isAuthRole) {
              return <PatientEditDetailsComponent />;
            } else {
              return;
            }
          }}
        /> */}

        <Route
          path="/listviewpatients/:patientid/edit"
          exact
          component={(props) => <ProtectedRoute {...props} component={PatientEditDetailsComponent} />}
        />



        {/* <PrivateRoute
          path="/listviewpatients/:patientid"
          exact
          onUpdateAuthorizationStatus={handleUpdateAuthorizationStatus}
          onUpdateAuthorization={handleUpdateAuthorization}
          component={(props) => {
            console.log(props);
            if (isAuthorized || isAuthRole) {
              return <PatientEditComponent />;
            } else {
              return <div>Unauthorized Access</div>;
            }
          }}
        /> */}

        {/* <PrivateRoute
          path="/listviewpatients/:patientid/edit"
          exact
          onUpdateAuthorizationStatus={handleUpdateAuthorizationStatus}
          onUpdateAuthorization={handleUpdateAuthorization}
          component={(props) => {
            // Check if the user is authorized and render Hospitalists or show Unauthorized Access
            console.log(props);
            if (isAuthorized || isAuthRole) {
              return <PatientEditDetailsComponent />;
            } else {
              return;
            }
          }}
        /> */}

        <Route
          path="/hospital-list/:hospitalcode/edit"
          exact
          component={(props) => <ProtectedRoute {...props} component={Hospitalistdetail} />}
        />

{/* <PrivateRoute
          path="/hospital-list/:hospitalcode/edit"
          exact
          onUpdateAuthorizationStatus={handleUpdateAuthorizationStatus}
          onUpdateAuthorization={handleUpdateAuthorization}
          onUpdateAuthorization1={handleUpdateAuthorization1}
          onUpdateAuthorization2={handleUpdateAuthorization2}
          onUpdateAuthorization3={handleUpdateAuthorization3}
          component={(props) => {
            // Check if the user is authorized and render Hospitalists or show Unauthorized Access
            console.log(props);
            if (isAuthorized) {
              return <Hospitalistdetail />;
            } else {
              return;
            }
          }}
        /> */}

        <PrivateRoute
          path="/hospital-list"
          exact
          onUpdateAuthorizationStatus={handleUpdateAuthorizationStatus}
          onUpdateAuthorization={handleUpdateAuthorization}
          onUpdateAuthorization1={handleUpdateAuthorization1}
          onUpdateAuthorization2={handleUpdateAuthorization2}
          onUpdateAuthorization3={handleUpdateAuthorization3}
          component={(props) => {
            // Check if the user is authorized and render Hospitalists or show Unauthorized Access
            console.log(props);
            if (isAuthorized) {
              return <Hospitalists />;
            } else {
              return;
            }
          }}
        />


        <PrivateRoute
          path="/hospital-registration"
          exact
          onUpdateAuthorizationStatus={handleUpdateAuthorizationStatus}
          onUpdateAuthorization={handleUpdateAuthorization}
          onUpdateAuthorization1={handleUpdateAuthorization1}
          onUpdateAuthorization2={handleUpdateAuthorization2}
          onUpdateAuthorization3={handleUpdateAuthorization3}
          component={(props) => {
            // Check if the user is authorized and render Hospitalists or show Unauthorized Access
            console.log(props);
            if (isAuthorized) {
              return <HospitalDetails />;
            } else {
              return;
            }
          }}
        />
        {/* <Route
          path="/hospital-registration"
          exact
          component={(props) => <ProtectedRoute {...props} component={HospitalDetails}/>}
        /> */}


<PrivateRoute
          path="/chargesdetailslist"
          exact
          onUpdateAuthorizationStatus={handleUpdateAuthorizationStatus}
          onUpdateAuthorization={handleUpdateAuthorization}
          onUpdateAuthorization1={handleUpdateAuthorization1}
          onUpdateAuthorization2={handleUpdateAuthorization2}
          onUpdateAuthorization3={handleUpdateAuthorization3}
          component={(props) => {
            // Check if the user is authorized and render Hospitalists or show Unauthorized Access
            console.log(props);
            if (isAuthorized || isAuthRole) {
              return <ChargesDetailsList />;
            } else {
              return;
            }
          }}
        />


        {/* <Route
          path="/chargesdetailslist"
          exact
          component={(props) => <ProtectedRoute {...props} component={ChargesDetailsList} />}
        /> */}


<PrivateRoute
          path="/charges"
          exact
          onUpdateAuthorizationStatus={handleUpdateAuthorizationStatus}
          onUpdateAuthorization={handleUpdateAuthorization}
          onUpdateAuthorization1={handleUpdateAuthorization1}
          onUpdateAuthorization2={handleUpdateAuthorization2}
          onUpdateAuthorization3={handleUpdateAuthorization3}
          component={(props) => {
            // Check if the user is authorized and render Hospitalists or show Unauthorized Access
            console.log(props);
            if (isAuthorized || isAuthRole) {
              return <AddNewCharge />;
            } else {
              return;
            }
          }}
        />

        {/* <Route
          path="/charges"
          exact
          component={(props) => <ProtectedRoute {...props} component={AddNewCharge} />}
        /> */}


        {/* <Route
          path="/billingpage"
          exact
          component={(props) => <ProtectedRoute {...props} component={BillingPageComponent} />}
        /> */}

        {/* <Route
          path="/billing"
          exact
          component={(props) => <ProtectedRoute {...props} component={BillingListViewComponent} />}
        /> */}

        
        <PrivateRoute
          path="/billingpage"
          exact
          onUpdateAuthorizationStatus={handleUpdateAuthorizationStatus}
          onUpdateAuthorization={handleUpdateAuthorization}
          onUpdateAuthorization1={handleUpdateAuthorization1}
          onUpdateAuthorization2={handleUpdateAuthorization2}
          onUpdateAuthorization3={handleUpdateAuthorization3}
          component={(props) => {
            // Check if the user is authorized and render Hospitalists or show Unauthorized Access
            console.log(props);
            if (isAuthorized || isAuthRole || isAuthRole1) {
              return <BillingPageComponent />;
            } else {
              return;
            }
          }}
        />
        <PrivateRoute
          path="/billing"
          exact
          onUpdateAuthorizationStatus={handleUpdateAuthorizationStatus}
          onUpdateAuthorization={handleUpdateAuthorization}
          onUpdateAuthorization1={handleUpdateAuthorization1}
          onUpdateAuthorization2={handleUpdateAuthorization2}
          onUpdateAuthorization3={handleUpdateAuthorization3}
          component={(props) => {
            // Check if the user is authorized and render Hospitalists or show Unauthorized Access
            console.log(props);
            if (isAuthorized || isAuthRole || isAuthRole1) {
              return <BillingListViewComponent />;
            } else {
              return;
            }
          }}
        />


{/* <PrivateRoute
          path="/listviewstaff/:empid"
          exact
          onUpdateAuthorizationStatus={handleUpdateAuthorizationStatus}
          onUpdateAuthorization={handleUpdateAuthorization}
          onUpdateAuthorization1={handleUpdateAuthorization1}
          onUpdateAuthorization2={handleUpdateAuthorization2}
          component={(props) => {
            // Check if the user is authorized and render Hospitalists or show Unauthorized Access
            console.log(props);
            if (isAuthorized || isAuthRole) {
              return <StaffEditComponent />;
            } else {
              return;
            }
          }}
        /> */}


        <Route
          path="/listviewstaff/:empid"
          exact
          component={(props) => <ProtectedRoute {...props} component={StaffEditComponent} />}
        />


{/* <PrivateRoute
          path="/listviewstaff/:empid/edit"
          exact
          onUpdateAuthorizationStatus={handleUpdateAuthorizationStatus}
          onUpdateAuthorization={handleUpdateAuthorization}
          onUpdateAuthorization1={handleUpdateAuthorization1}
          onUpdateAuthorization2={handleUpdateAuthorization2}
          component={(props) => {
            // Check if the user is authorized and render Hospitalists or show Unauthorized Access
            console.log(props);
            if (isAuthorized || isAuthRole) {
              return <StaffEditDetailsComponent />;
            } else {
              return;
            }
          }}
        /> */}


        <Route
          path="/listviewstaff/:empid/edit"
          exact
          component={(props) => <ProtectedRoute {...props} component={StaffEditDetailsComponent} />}
        />


        {/* <PrivateRoute
          path="/listviewstaff/:empid/edit"
          exact
          onUpdateAuthorizationStatus={handleUpdateAuthorizationStatus}
          onUpdateAuthorization={handleUpdateAuthorization}
          component={(props) => {
            // Check if the user is authorized and render Hospitalists or show Unauthorized Access
            console.log(props);
            if (isAuthorized || isAuthRole) {
              return <StaffEditDetailsComponent />;
            } else {
              return;
            }
          }}
        />
        <PrivateRoute
          path="/listviewstaff/:empid"
          exact
          onUpdateAuthorizationStatus={handleUpdateAuthorizationStatus}
          onUpdateAuthorization={handleUpdateAuthorization}
          component={(props) => {
            // Check if the user is authorized and render Hospitalists or show Unauthorized Access
            console.log(props);
            if (isAuthorized || isAuthRole) {
              return <StaffEditComponent />;
            } else {
              return;
            }
          }}
        /> */}



        <PrivateRoute
          path="/listviewstaff"
          exact
          onUpdateAuthorizationStatus={handleUpdateAuthorizationStatus}
          onUpdateAuthorization={handleUpdateAuthorization}
          onUpdateAuthorization1={handleUpdateAuthorization1}
          onUpdateAuthorization2={handleUpdateAuthorization2}
          onUpdateAuthorization3={handleUpdateAuthorization3}
          component={(props) => {
            // Check if the user is authorized and render Hospitalists or show Unauthorized Access
            console.log(props);
            if (isAuthorized || isAuthRole) {
              return <ListViewComponent />;
            } else {
              return;
            }
          }}
        />
{/* 
<PrivateRoute
          path="/editbilling/:billnumber"
          exact
          onUpdateAuthorizationStatus={handleUpdateAuthorizationStatus}
          onUpdateAuthorization={handleUpdateAuthorization}
          onUpdateAuthorization1={handleUpdateAuthorization1}
          onUpdateAuthorization2={handleUpdateAuthorization2}
          component={(props) => {
            // Check if the user is authorized and render Hospitalists or show Unauthorized Access
            console.log(props);
            if (isAuthorized || isAuthRole) {
              return <EditBillingComponent />;
            } else {
              return;
            }
          }}
        /> */}


        <Route
          path="/editbilling/:billnumber"
          exact
          component={(props) => <ProtectedRoute {...props} component={EditBillingComponent} />}
        ></Route>


<PrivateRoute
          path="/appointmentreport"
          exact
          onUpdateAuthorizationStatus={handleUpdateAuthorizationStatus}
          onUpdateAuthorization={handleUpdateAuthorization}
          onUpdateAuthorization1={handleUpdateAuthorization1}
          onUpdateAuthorization2={handleUpdateAuthorization2}
          onUpdateAuthorization3={handleUpdateAuthorization3}
          component={(props) => {
            // Check if the user is authorized and render Hospitalists or show Unauthorized Access
            console.log(props);
            if (isAuthorized || isAuthRole) {
              return <ReportAppointment />;
            } else {
              return;
            }
          }}
        />


        {/* <Route
          path="/appointmentreport"
          exact
          component={(props) => <ProtectedRoute {...props} component={ReportAppointment} />}
        ></Route> */}


<PrivateRoute
          path="/billingreport"
          exact
          onUpdateAuthorizationStatus={handleUpdateAuthorizationStatus}
          onUpdateAuthorization={handleUpdateAuthorization}
          onUpdateAuthorization1={handleUpdateAuthorization1}
          onUpdateAuthorization2={handleUpdateAuthorization2}
          onUpdateAuthorization3={handleUpdateAuthorization3}
          component={(props) => {
            // Check if the user is authorized and render Hospitalists or show Unauthorized Access
            console.log(props);
            if (isAuthorized || isAuthRole) {
              return <ReportBilling />;
            } else {
              return;
            }
          }}
        />

        {/* <Route
          path="/billingreport"
          exact
          component={(props) => <ProtectedRoute {...props} component={ReportBilling} />}
        ></Route> */}



        <PrivateRoute
          path="/cancelbillinglist"
          exact
          onUpdateAuthorizationStatus={handleUpdateAuthorizationStatus}
          onUpdateAuthorization={handleUpdateAuthorization}
          onUpdateAuthorization1={handleUpdateAuthorization1}
          onUpdateAuthorization2={handleUpdateAuthorization2}
          onUpdateAuthorization3={handleUpdateAuthorization3}
          component={(props) => {
            // Check if the user is authorized and render Hospitalists or show Unauthorized Access
            console.log(props);
            if (isAuthorized || isAuthRole) {
              return <CancelBillListComponent />;
            } else {
              return;
            }
          }}
        />


{/* <PrivateRoute
   
   path="/addradiologycatagory"
   exact
   onUpdateAuthorizationStatus={handleUpdateAuthorizationStatus}
   onUpdateAuthorization={handleUpdateAuthorization}
   onUpdateAuthorization1={handleUpdateAuthorization1}
   onUpdateAuthorization2={handleUpdateAuthorization2}
   component={(props) => {
     // Check if the user is authorized and render Hospitalists or show Unauthorized Access
     console.log(props);
     if (isAuthRole2) {
       return <RadiologyChategory />;
     } else {
       return  ;
     }
   }}
 /> */}

        <Route
          path="/addradiologycatagory"
          exact
          component={(props) => <ProtectedRoute {...props} component={RadiologyChategory} />}
        ></Route>

{/* <PrivateRoute
   
   path="/listrediologycatagory"
   exact
   onUpdateAuthorizationStatus={handleUpdateAuthorizationStatus}
   onUpdateAuthorization={handleUpdateAuthorization}
   onUpdateAuthorization1={handleUpdateAuthorization1}
   onUpdateAuthorization2={handleUpdateAuthorization2}
   component={(props) => {
     // Check if the user is authorized and render Hospitalists or show Unauthorized Access
     console.log(props);
     if (isAuthRole2) {
       return <ListViewRadiologyChategories />;
     } else {
       return  ;
     }
   }}
 /> */}

         <Route
          path="/listrediologycatagory"
          exact
          component={(props) => <ProtectedRoute {...props} component={ListViewRadiologyChategories} />}
        ></Route>


{/* <PrivateRoute
   
   path="/addradiologyunit"
   exact
   onUpdateAuthorizationStatus={handleUpdateAuthorizationStatus}
   onUpdateAuthorization={handleUpdateAuthorization}
   onUpdateAuthorization1={handleUpdateAuthorization1}
   onUpdateAuthorization2={handleUpdateAuthorization2}
   component={(props) => {
     // Check if the user is authorized and render Hospitalists or show Unauthorized Access
     console.log(props);
     if (isAuthRole2) {
       return <AddRadiologyUnits />;
     } else {
       return  ;
     }
   }}
 /> */}
        <Route
          path="/addradiologyunit"
          exact
          component={(props) => <ProtectedRoute {...props} component={AddRadiologyUnits} />}
        ></Route>


      

{/* <PrivateRoute
   
   path="/listrediologyunit"
   exact
   onUpdateAuthorizationStatus={handleUpdateAuthorizationStatus}
   onUpdateAuthorization={handleUpdateAuthorization}
   onUpdateAuthorization1={handleUpdateAuthorization1}
   onUpdateAuthorization2={handleUpdateAuthorization2}
   component={(props) => {
     // Check if the user is authorized and render Hospitalists or show Unauthorized Access
     console.log(props);
     if (isAuthRole2) {
       return <ListViewAddRadiologyUnits />;
     } else {
       return  ;
     }
   }}
 />   */}
         <Route
          path="/listrediologyunit"
          exact
          component={(props) => <ProtectedRoute {...props} component={ListViewAddRadiologyUnits} />}
        ></Route>


{/* <PrivateRoute
   
   path="/addradiology"
   exact
   onUpdateAuthorizationStatus={handleUpdateAuthorizationStatus}
   onUpdateAuthorization={handleUpdateAuthorization}
   onUpdateAuthorization1={handleUpdateAuthorization1}
   onUpdateAuthorization2={handleUpdateAuthorization2}
   component={(props) => {
     // Check if the user is authorized and render Hospitalists or show Unauthorized Access
     console.log(props);
     if (isAuthRole2) {
       return <AddRadiologyComponent />;
     } else {
       return  ;
     }
   }}
 />   */}


  <Route
          path="/addradiology"
          exact
          component={(props) => <ProtectedRoute {...props} component={AddRadiologyComponent} />}
        />


<PrivateRoute
   
   path="/editradiology/:billno/:radiologyreportid"
   exact
   onUpdateAuthorizationStatus={handleUpdateAuthorizationStatus}
   onUpdateAuthorization={handleUpdateAuthorization}
   onUpdateAuthorization1={handleUpdateAuthorization1}
   onUpdateAuthorization2={handleUpdateAuthorization2}
   onUpdateAuthorization3={handleUpdateAuthorization3}
   component={(props) => {
     // Check if the user is authorized and render Hospitalists or show Unauthorized Access
     console.log(props);
     if (isAuthRole2) {
       return <EditRadiologyReportComponent />;
     } else {
       return  ;
     }
   }}
 />   

{/* <Route
          path="/editradiology/:billno/:radiologyreportid"
          exact
          component={(props) => <ProtectedRoute {...props} component={EditRadiologyReportComponent} />}
        /> */}
  {/* <Route
          path="/radiologyreport"
          exact
          component={(props) => <ProtectedRoute {...props} component={RadiologyReportComponent} />}
        /> */}



<PrivateRoute
   
   path="/radiologyreport"
   exact
   onUpdateAuthorizationStatus={handleUpdateAuthorizationStatus}
   onUpdateAuthorization={handleUpdateAuthorization}
   onUpdateAuthorization1={handleUpdateAuthorization1}
   onUpdateAuthorization2={handleUpdateAuthorization2}
   onUpdateAuthorization3={handleUpdateAuthorization3}
   component={(props) => {
     // Check if the user is authorized and render Hospitalists or show Unauthorized Access
     console.log(props);
     if (isAuthRole2) {
       return <RadiologyReportComponent />;
     } else {
       return  ;
     }
   }}
 />

          <Route
          path="/radiologyreportlist"
          exact
          component={(props) => <ProtectedRoute {...props} component={ListRadiologyReportComponent} />}
        />
     <Route
          path="/listviewradiology"
          exact
          component={(props) => <ProtectedRoute {...props} component={ListViewRadiologyComponent} />}
        />



<PrivateRoute
          path="/addmedicineunit"
          exact
          onUpdateAuthorizationStatus={handleUpdateAuthorizationStatus}
          onUpdateAuthorization={handleUpdateAuthorization}
          onUpdateAuthorization1={handleUpdateAuthorization1}
          onUpdateAuthorization2={handleUpdateAuthorization2}
          onUpdateAuthorization3={handleUpdateAuthorization3}
          component={(props) => {
            // Check if the user is authorized and render Hospitalists or show Unauthorized Access
            console.log(props);
            if (isAuthorized || isAuthRole) {
              return <AddMedicineUnitComponent />;
            } else {
              return;
            }
          }}
        />



{/* <Route
          path="/addmedicineunit"
          exact
          component={(props) => <ProtectedRoute {...props} component={AddMedicineUnitComponent} />}
        ></Route> */}




<PrivateRoute
          path="/listviewmedicineunit"
          exact
          onUpdateAuthorizationStatus={handleUpdateAuthorizationStatus}
          onUpdateAuthorization={handleUpdateAuthorization}
          onUpdateAuthorization1={handleUpdateAuthorization1}
          onUpdateAuthorization2={handleUpdateAuthorization2}
          onUpdateAuthorization3={handleUpdateAuthorization3}
          component={(props) => {
            // Check if the user is authorized and render Hospitalists or show Unauthorized Access
            console.log(props);
            if (isAuthorized || isAuthRole) {
              return <ListViewMedicineUnitComponent />;
            } else {
              return;
            }
          }}
        />



     {/* <Route
          path="/listviewmedicineunit"
          exact
          component={(props) => <ProtectedRoute {...props} component={ListViewMedicineUnitComponent} />}
        ></Route> */}



{/* <PrivateRoute
          path="/editmedicineunit/:medicineunitid"
          exact
          onUpdateAuthorizationStatus={handleUpdateAuthorizationStatus}
          onUpdateAuthorization={handleUpdateAuthorization}
          onUpdateAuthorization1={handleUpdateAuthorization1}
          onUpdateAuthorization2={handleUpdateAuthorization2}
          component={(props) => {
            // Check if the user is authorized and render Hospitalists or show Unauthorized Access
            console.log(props);
            if (isAuthorized || isAuthRole) {
              return <EditMedicineUnitComponent />;
            } else {
              return;
            }
          }}
        /> */}



    <Route
          path="/editmedicineunit/:medicineunitid"
          exact
          component={(props) => <ProtectedRoute {...props} component={EditMedicineUnitComponent} />}
        />

<PrivateRoute
          path="/addmedicinemanufacturer"
          exact
          onUpdateAuthorizationStatus={handleUpdateAuthorizationStatus}
          onUpdateAuthorization={handleUpdateAuthorization}
          onUpdateAuthorization1={handleUpdateAuthorization1}
          onUpdateAuthorization2={handleUpdateAuthorization2}
          onUpdateAuthorization3={handleUpdateAuthorization3}
          component={(props) => {
            // Check if the user is authorized and render Hospitalists or show Unauthorized Access
            console.log(props);
            if (isAuthorized || isAuthRole) {
              return <AddMedicineManufacturerComponent />;
            } else {
              return;
            }
          }}
        />


{/* <Route
          path="/addmedicinemanufacturer"
          exact
          component={(props) => <ProtectedRoute {...props} component={AddMedicineManufacturerComponent} />}
        ></Route> */}



<PrivateRoute
          path="/listviewmedicinemanufacturer"
          exact
          onUpdateAuthorizationStatus={handleUpdateAuthorizationStatus}
          onUpdateAuthorization={handleUpdateAuthorization}
          onUpdateAuthorization1={handleUpdateAuthorization1}
          onUpdateAuthorization2={handleUpdateAuthorization2}
          onUpdateAuthorization3={handleUpdateAuthorization3}
          component={(props) => {
            // Check if the user is authorized and render Hospitalists or show Unauthorized Access
            console.log(props);
            if (isAuthorized || isAuthRole) {
              return <ListViewMedicineManufacturerComponent />;
            } else {
              return;
            }
          }}
        />



   {/* <Route
          path="/listviewmedicinemanufacturer"
          exact
          component={(props) => <ProtectedRoute {...props} component={ListViewMedicineManufacturerComponent} />}
        ></Route> */}

{/* <PrivateRoute
          path="/editmedicinemanufacturer/:medicinemanufacturerid"
          exact
          onUpdateAuthorizationStatus={handleUpdateAuthorizationStatus}
          onUpdateAuthorization={handleUpdateAuthorization}
          onUpdateAuthorization1={handleUpdateAuthorization1}
          onUpdateAuthorization2={handleUpdateAuthorization2}
          component={(props) => {
            // Check if the user is authorized and render Hospitalists or show Unauthorized Access
            console.log(props);
            if (isAuthorized || isAuthRole) {
              return <EditMedicineManufacturerComponent />;
            } else {
              return;
            }
          }}
        /> */}


   <Route
          path="/editmedicinemanufacturer/:medicinemanufacturerid"
          exact
          component={(props) => <ProtectedRoute {...props} component={EditMedicineManufacturerComponent} />}
        />

<PrivateRoute
          path="/addmedicinecatagory"
          exact
          onUpdateAuthorizationStatus={handleUpdateAuthorizationStatus}
          onUpdateAuthorization={handleUpdateAuthorization}
          onUpdateAuthorization1={handleUpdateAuthorization1}
          onUpdateAuthorization2={handleUpdateAuthorization2}
          onUpdateAuthorization3={handleUpdateAuthorization3}
          component={(props) => {
            // Check if the user is authorized and render Hospitalists or show Unauthorized Access
            console.log(props);
            if (isAuthorized || isAuthRole) {
              return <MedicineChategory />;
            } else {
              return;
            }
          }}
        />



         {/* <Route
          path="/addmedicinecatagory"
          exact
          component={(props) => <ProtectedRoute {...props} component={MedicineChategory} />}
        ></Route> */}

<PrivateRoute
          path="/listmedicinecatagory"
          exact
          onUpdateAuthorizationStatus={handleUpdateAuthorizationStatus}
          onUpdateAuthorization={handleUpdateAuthorization}
          onUpdateAuthorization1={handleUpdateAuthorization1}
          onUpdateAuthorization2={handleUpdateAuthorization2}
          onUpdateAuthorization3={handleUpdateAuthorization3}
          component={(props) => {
            // Check if the user is authorized and render Hospitalists or show Unauthorized Access
            console.log(props);
            if (isAuthorized || isAuthRole) {
              return <ListViewMedicineChategory />;
            } else {
              return;
            }
          }}
        />
<PrivateRoute
          path="/pharmacybilling"
          exact
          onUpdateAuthorizationStatus={handleUpdateAuthorizationStatus}
          onUpdateAuthorization={handleUpdateAuthorization}
          onUpdateAuthorization1={handleUpdateAuthorization1}
          onUpdateAuthorization2={handleUpdateAuthorization2}
          onUpdateAuthorization3={handleUpdateAuthorization3}
          component={(props) => {
            // Check if the user is authorized and render Hospitalists or show Unauthorized Access
            console.log(props);
            if (isAuthorized || isAuthRole) {
              return <MedicineBillings />;
            } else {
              return;
            }
          }}
        />
<PrivateRoute
          path="/newpharmacypurchase"
          exact
          onUpdateAuthorizationStatus={handleUpdateAuthorizationStatus}
          onUpdateAuthorization={handleUpdateAuthorization}
          onUpdateAuthorization1={handleUpdateAuthorization1}
          onUpdateAuthorization2={handleUpdateAuthorization2}
          onUpdateAuthorization3={handleUpdateAuthorization3}
          component={(props) => {
            // Check if the user is authorized and render Hospitalists or show Unauthorized Access
            console.log(props);
            if (isAuthorized || isAuthRole) {
              return <PurchaseMedicine/>;
            } else {
              return;
            }
          }}
        />
<PrivateRoute
          path="/pharmacypurchase"
          exact
          onUpdateAuthorizationStatus={handleUpdateAuthorizationStatus}
          onUpdateAuthorization={handleUpdateAuthorization}
          onUpdateAuthorization1={handleUpdateAuthorization1}
          onUpdateAuthorization2={handleUpdateAuthorization2}
          onUpdateAuthorization3={handleUpdateAuthorization3}
          component={(props) => {
            // Check if the user is authorized and render Hospitalists or show Unauthorized Access
            console.log(props);
            if (isAuthorized || isAuthRole) {
              return <PurchaseList />;
            } else {
              return;
            }
          }}
        />
<PrivateRoute
          path="/pharmacybillinglist"
          exact
          onUpdateAuthorizationStatus={handleUpdateAuthorizationStatus}
          onUpdateAuthorization={handleUpdateAuthorization}
          onUpdateAuthorization1={handleUpdateAuthorization1}
          onUpdateAuthorization2={handleUpdateAuthorization2}
          onUpdateAuthorization3={handleUpdateAuthorization3}
          component={(props) => {
            // Check if the user is authorized and render Hospitalists or show Unauthorized Access
            console.log(props);
            if (isAuthorized || isAuthRole) {
              return <MedicineBillingList />;
            } else {
              return;
            }
          }}
        />
         {/* <Route
          path="/listmedicinecatagory"
          exact
          component={(props) => <ProtectedRoute {...props} component={ListViewMedicineChategory} />}
        ></Route> */}

<PrivateRoute
          path="/addmedicine"
          exact
          onUpdateAuthorizationStatus={handleUpdateAuthorizationStatus}
          onUpdateAuthorization={handleUpdateAuthorization}
          onUpdateAuthorization1={handleUpdateAuthorization1}
          onUpdateAuthorization2={handleUpdateAuthorization2}
          onUpdateAuthorization3={handleUpdateAuthorization3}
          component={(props) => {
            // Check if the user is authorized and render Hospitalists or show Unauthorized Access
            console.log(props);
            if (isAuthorized || isAuthRole) {
              return <AddMedicines />;
            } else {
              return;
            }
          }}
        />

        {/* <Route
          path="/addmedicine"
          exact
          component={(props) => <ProtectedRoute {...props} component={AddMedicines} />}
        ></Route> */}

<PrivateRoute
          path="/listmedicine"
          exact
          onUpdateAuthorizationStatus={handleUpdateAuthorizationStatus}
          onUpdateAuthorization={handleUpdateAuthorization}
          onUpdateAuthorization1={handleUpdateAuthorization1}
          onUpdateAuthorization2={handleUpdateAuthorization2}
          onUpdateAuthorization3={handleUpdateAuthorization3}
          component={(props) => {
            // Check if the user is authorized and render Hospitalists or show Unauthorized Access
            console.log(props);
            if (isAuthorized || isAuthRole) {
              return <ListViewMedicinesComponent />;
            } else {
              return;
            }
          }}
        />

        {/* <Route
          path="/listmedicine"
          exact
          component={(props) => <ProtectedRoute {...props} component={ListViewMedicinesComponent} />}
        ></Route> */}



       {/* <PrivateRoute
   
          path="/addpathologycategory"
          exact
          onUpdateAuthorizationStatus={handleUpdateAuthorizationStatus}
          onUpdateAuthorization={handleUpdateAuthorization}
          onUpdateAuthorization1={handleUpdateAuthorization1}
          onUpdateAuthorization2={handleUpdateAuthorization2}
          component={(props) => {
            // Check if the user is authorized and render Hospitalists or show Unauthorized Access
            console.log(props);
            if (isAuthRole1) {
              return <AddPathologyCategoryComponent />;
            } else {
              return  ;
            }
          }}
        /> */}
        <Route
          path="/editpurchase/:purchaseid"
          exact
          component={(props) => <ProtectedRoute {...props} component={EditPurchaseComponent} />}
        ></Route>
  <Route
          path="/editpharmacybilling/:medicinebillingid"
          exact
          component={(props) => <ProtectedRoute {...props} component={EditPharmacyBillingDetails} />}
        ></Route>

        <Route
          path="/addpathologycategory"
          exact
          component={(props) => <ProtectedRoute {...props} component={AddPathologyCategoryComponent} />}
        />


<Route
          path="/editmedicinecategory/:medicinecategoryid"
          exact
          component={(props) => <ProtectedRoute {...props} component={EditMedicineCategoryComponent} />}
        />


<Route
          path="/editmedicine/:medicineid"
          exact
          component={(props) => <ProtectedRoute {...props} component={EditMedicineComponent} />}
        />
        <Route
          path="/listviewpathologycategory"
          exact
          component={(props) => <ProtectedRoute {...props} component={ListViewPathologyCategoryComponent} />}
        />
{/* 
<PrivateRoute
   
   path="/addunit"
   exact
   onUpdateAuthorizationStatus={handleUpdateAuthorizationStatus}
   onUpdateAuthorization={handleUpdateAuthorization}
   onUpdateAuthorization1={handleUpdateAuthorization1}
   onUpdateAuthorization2={handleUpdateAuthorization2}
   component={(props) => {
     // Check if the user is authorized and render Hospitalists or show Unauthorized Access
     console.log(props);
     if (isAuthRole1) {
       return <AddUnitComponent />;
     } else {
       return  ;
     }
   }}
 /> */}

     <Route
          path="/addunit"
          exact
          component={(props) => <ProtectedRoute {...props} component={AddUnitComponent} />}
        />


   <Route
          path="/listviewunit"
          exact
          component={(props) => <ProtectedRoute {...props} component={ListViewUnitComponent} />}
        />
{/* 
<PrivateRoute
   
   path="/addpathology"
   exact
   onUpdateAuthorizationStatus={handleUpdateAuthorizationStatus}
   onUpdateAuthorization={handleUpdateAuthorization}
   onUpdateAuthorization1={handleUpdateAuthorization1}
   onUpdateAuthorization2={handleUpdateAuthorization2}
   component={(props) => {
     // Check if the user is authorized and render Hospitalists or show Unauthorized Access
     console.log(props);
     if (isAuthRole1) {
       return <AddPathologyComponent />;
     } else {
       return  ;
     }
   }}
 /> */}
 
    <Route
          path="/addpathology"
          exact
          component={(props) => <ProtectedRoute {...props} component={AddPathologyComponent} />}
        />

     <Route
          path="/listviewpathology"
          exact
          component={(props) => <ProtectedRoute {...props} component={ListViewPathologyComponent} />}
        />


        <Route
          path="/pathologyreport"
          exact
          component={(props) => <ProtectedRoute {...props} component={PathologyReportComponent} />}
        />


      <Route
          path="/editpathology/:billingno"
          exact
          component={(props) => <ProtectedRoute {...props} component={EditPathologyReportComponent} />}
        />


<Route
          path="/editradiologycategory/:radiologycategoryid"
          exact
          component={(props) => <ProtectedRoute {...props} component={EditRadiologyCategoryComponent} />}
        />
        <Route
          path="/editradiologyparameter/:radiologycode"
          exact
          component={(props) => <ProtectedRoute {...props} component={EditRadiologyParameter} />}
        />
<Route
          path="/editpathologycategory/:chargecode"
          exact
          component={(props) => <ProtectedRoute {...props} component={EditPathologyCategory} />}
        />
        <Route
          path="/editpathologyunit/:unitid"
          exact
          component={(props) => <ProtectedRoute {...props} component={EditPathologyUnit} />}
        />
         <Route
          path="/editpathologyparameter/:pathologycode"
          exact
          component={(props) => <ProtectedRoute {...props} component={EditPathologyParameter} />}
        />
   <PrivateRoute
   
          path="/addpathologyreport"
          exact
          onUpdateAuthorizationStatus={handleUpdateAuthorizationStatus}
          onUpdateAuthorization={handleUpdateAuthorization}
          onUpdateAuthorization1={handleUpdateAuthorization1}
          onUpdateAuthorization2={handleUpdateAuthorization2}
          onUpdateAuthorization3={handleUpdateAuthorization3}
          component={(props) => {
            // Check if the user is authorized and render Hospitalists or show Unauthorized Access
            console.log(props);
            if (isAuthRole1) {
              return <PathologyReportForm />;
            } else {
              return  ;
            }
          }}
        />


{/* <Route
  path="/addpathologyreport"
  exact
  render={() =>
    userdesignation === "Pathologist" ? (
      <PrivateRoute component={PathologyReportForm} />
    ) : (
      <Redirect to="/unauthorized" />
    )
  }
/> */}


        <Route path="/unauthorized" component={() => <h1>Unauthorized Access!</h1>} />
        <Route path="*" component={() => <h1>Error 404 page not found !!</h1>} />
      </Switch>
    </Router>
  );
}

export default App;